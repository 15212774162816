<template>
  <div
    class="agreement mui-scroll-wrapper"
    style="height: 100%; overflow: scroll"
  >
    <div class="mui-scroll">
      <div class="mui-content">
        <div class="mui-card">
          <div
            v-html="info.content"
            class="mui-card-content-inner text-margin mui-scroll"
          ></div>
        </div>
        <div class="mui-button-row">
          <button
            type="button"
            :disabled="isDisable"
            :style="`backgroundColor:${$themeColor}`"
            class="mui-btn updatePwd_btn"
            @click="agreeHandle"
          >
            {{ code_tip }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAgreemen } from "@/api/index.js";
export default {
  name: "agreement",
  props: {
    term_type: {
      type: String,
      default: "patient_reg",
    },
  },
  data() {
    return {
      info: {},
      windowHeight: "100%",
      isDisable: true,
      code_time: "",
      readTime: "",
      code_tip: null,
      term_type_index: this.term_type,
      status: 1,
    };
  },
  components: {},
  created() {
    var mui = this.mui;
    var that = this;
    console.log("that.$route.query:", that.$route.query);

    if (that.$route.query.term_type_index) {
      this.term_type_index = that.$route.query.term_type_index;
    }

    that.readyInfo();
    console.log("that.windowHeight", that.windowHeight);
    mui.init({
      swipeBack: true, //启用右滑关闭功能
    });
    mui(".mui-scroll-wrapper").scroll({
      indicators: true, //是否显示滚动条
    });
  },
  methods: {
    /**
     * 同意协议
     */
    agreeHandle() {
      const mui = this.mui;
      this.$store.commit("ckeckVal", true);
      console.log("agreeHandle", this.term_type_index);
      switch (this.term_type_index) {
        case "patient_reg":
          this.$router.go(-1);
          this.$store.commit("ckeckVal", true);
          break;
        case "patient_service":
        case "patient_privacy":
          try {
            // eslint-disable-next-line no-undef
            appClient.getTermStatus(this.term_type_index, this.status);
          } catch (e) {
            this.mui.toast(
              "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
            );
          }
          break;
        case "patient_delete":
          this.$emit("agreeHandle");
          this.$router.go(-1);
          break;
        // 门诊预约
        case "order_book_item_agreement":
          try {
            // eslint-disable-next-line no-undef
            appClient.registrationRule();
          } catch (e) {
            mui.toast(
              "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
            );
          }
          break;
        case "patient_reg":
          try {
            // eslint-disable-next-line no-undef
            this.$router.go(-1);
          } catch (e) {
            mui.toast(
              "出现错误, 如果在非android环境下访问, 出现该警告是正常的(注册)."
            );
          }
          break;
        default:
          //"create_cons"// 会诊
          //"create_referral"//转诊
          //"patient_know" //患者知情同意
          //"patient_reg" //患者注册
          try {
            // eslint-disable-next-line no-undef
            appClient.agree();
            // this.$router.go(-1);
          } catch (e) {
            mui.toast(
              "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
            );
          }
          break;
      }
    },
    /**
     * 获取协议内容
     * 	//7为知情同意书 8为注册协议 9为注销协议
     */
    async readyInfo() {
      try {
        var that = this;
        var params = {
          type: this.term_type_index,
        };
        let res = await getAgreemen(params).then(that.readyInfoHandle);
        if (res.success) {
          that.info = res.result.records[0];
          this.readTime = res.result.records[0].readTime;
          this.code_time = this.readTime;
          this.countDown();
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
     * 倒计时
     *
     */
    countDown() {
      const timer = setInterval(() => {
        this.code_tip = this.code_time + "s";
        this.code_time--;
        if (this.code_time < 0) {
          clearInterval(timer);
          this.isDisable = false;
          this.code_tip = "已同意相关的协议";
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.agreement .text-margin {
  /* padding: 10px !important; */
  margin: 0;
  font-size: 18px;
}

.mui-card-content-inner {
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}
.updatePwd_btn {
  width: 80% !important;
  height: 40px !important;
  display: block !important;
  margin: 0 auto !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  border: none !important;
  color: #fff !important;
}
</style>
