import { req } from '@/axios/index.js';

//获取短信接口
export const getCaptcha = function (params) {
    return req('post', 'member/login/getCaptcha', params)
};

//获取个人资料
export const getSickInfo = function (params) {
    return req('get', 'member/information/queryById', params)
};

//全局获取
export const initConfig = function (params) {
    return req('get', 'config/globalConfig', params)
};

//修改个人资料
export const upEditInfo = function (params) {
    return req('put', 'member/information/edit', params)
};

//修改个人资料的头像
export const upEditInfoAvatar = function (params) {
    return req('get', 'member/information/editAvatar', params)
};

//协议
export const getAgreemen = function (params) {
    return req('get', 'patient/agreement/list', params)
};

//注册账号
export const publicsReg = function (params) {
    return req('post', 'member/login/reg', params)
};

//关于我们
export const getAbout = function (params) {
    return req('get', 'member/index/aboutUs', params)
};

//忘记密码
export const forgetPassword = function (params) {
    return req('get', 'member/login/updatePassword', params)
};

//修改手机号接口
export const changMobile = function (params) {
    return req('get', 'member/login/updateMobile', params)
};

//注销账户接口
// export const deleteAccount = function (params) {
//   return req('delete', 'member/information/delete', params)
// };
export const deleteAccount = function (params) {
    return req('get', 'member/login/logout', params)
};

//获取疾病列表
export const disease_code = function (params) {
    return req('get', 'member/hisDisease/list', params)
};

//咨询详情
export const getAdviseDetail = function (params) {
    return req('get', 'patient/sysAdvise/queryById', params)
};

//添加收藏
export const addCollection = function (params) {
    return req('get', 'member/collection/add', params)
};

//获取处方详情报告数据
export const getPrescription = function (params) {
    return req('get', 'member/report/detail', params)
};

//获取区域上下级形式
export const getAreaByPid = function (params) {
    return req('get', 'member/region/getAreaByPid', params)
};

//获取医院
export const getHospital = function (params) {
    return req('get', 'member/hisHospital/list', params)
};

//安卓下载
export const getDownload = function (params) {
    return req('get', 'patient/version/newApp', params)
};

//核酸检测预约列表
export const getdetection = function (params) {
    return req('get', 'member/hisOrderBookExamine/list', params)
};

//核酸检测预约详情
export const getdetectionDetail = function (params) {
    return req('get', 'member/hisOrderBookExamine/queryById', params)
};

//获取病史列表
export const hisDiseaseList = function (params) {
  return req('get', 'sys/hisDepartmentDisease/list', params)
};

//获取用药史列表
export const sysDrug = function (params) {
  return req('get', 'sys/sysDrug/list', params)
};

//获取过敏史列表
export const hisAllergic = function (params) {
  return req('get', 'sys/hisAllergic/list', params)
};
//我的门诊预约列表
export const orderBookItemList = function (params) {
  return req('get', '/member/orderBookItem/list', params)
};
//我的门诊预约详情
export const orderBookItemDetail = function (params) {
    return req('get', '/member/orderBookItem/queryById', params)
};

//im 聊天记录
export const orderTreatProcess = function (params) {
    return req('get', '/member/orderTreat/orderTreatProcess', params)
};
//系统消息列表
export const messageList = function (params) {
    return req('get', '/member/annountCement/list', params)
};
//一键全读
export const readAll = function (params) {
    return req('get', '/member/annountCement/readAll', params)
};
//更新用户系统消息阅读状态
export const editReadStatus = function (params) {
    return req('get', '/member/annountCement/editReadStatus', params)
};
//获取用户详情接口
export const messageDetail = function (params) {
    return req('get', '/member/annountCement/info', params)
};
//患者化验报告  
export const laboratoryReport = function (params) {
    return req('get', '/member/laboratoryReport/queryById', params)
};

//ID获取病历详情
export const electronicCase = function (params) {
    return req('get', '/member/electronicCase/getInfoByOrderTreatId', params)
};
//快递鸟查询物流信息
export const kdniao = function(params) {
    return req('get',"/kdniao/query", params);
  };
//ID获取病历详情
export const nationList = function (params) {
    return req('get', '/member/nation/list', params)
};


