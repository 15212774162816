<template>
  <div class="mui-scroll-wrapper diseaseCode">
    <div class="mui-scroll">
      <!-- <header class="mui-bar mui-bar-nav" style="height: 50px">
        <div class="mui-segmented-control mui-segmented-control-inverted">
          <span class="mui-control-item">用药史</span>
        </div>
      </header> -->
      <div class="search_box">
        <div class="mui-input-row mui-search" style="width: 80%; margin: 5px 20px; padding: 0; position: absolute">
          <input type="text" class="search_input" v-model="title" placeholder="请输入关键字" />
        </div>
        <button class="mui-icon mui-pull-right search_btn mui-icon-search" @tap="search">
          <!-- 搜索 -->
          <!-- <img src="../" alt=""> -->
        </button>
      </div>
      <div class="tag_disease mui-card">
        <ul class="mui_tag_ul">
          <li v-for="(item, index) in sysDrugList" :key="index" class="tag_item">
            <span>{{item}}</span>
            <button href="JavaScript:;" class="del_btn" @click="deleteTag(index)">×</button>
          </li>
        </ul>

      </div>
      <div v-if="!has_data" class="mui-content">
        <div class="mui-card">
          <ul class="mui-table-view">
            <li class="mui-table-view-cell" @tap="diseaseInfo(item)" v-for="(item, index) in info" :key="index">
              <a class="mui-navigate-right">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="mui-content contentTab no-data" v-if="has_data">
        <h5 class="mui-text-center">暂无疾病信息</h5>
      </div>
    </div>
    <button class="btn_submit" type="submit" @click="onSubmitSysDrug" :style="`backgroundColor:${$themeColor}`">保存</button>
  </div>
</template>

<script>

import { sysDrug } from "@/api/index.js";
export default {
  components: {},
  name: "alarmMessageDetail",
  data() {
    return {
      info: [],
      sysDrugList: [],
      has_data: false,
      more: false,
      token: "",
      type: "",
      title: "",
      pageSize: 15,
      pageNo: 1,
      total: "",
      sysDrugItem:''
    };
  },
  mounted() {
    var mui = this.mui;
    var that = this;
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
    // 下拉刷新、上拉加载
    mui.init({
      pullRefresh: {
        container: ".mui-scroll-wrapper",
        down: {
          height: 50, // 可选,默认50.触发下拉刷新拖动距离,
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentdown: "下拉可以刷新", // 可选，在下拉可刷新状态时，下拉刷新控件上显示的标题内容
          contentover: "释放立即刷新", // 可选，在释放可刷新状态时，下拉刷新控件上显示的标题内容
          contentrefresh: "正在刷新...", // 可选，正在刷新状态时，下拉刷新控件上显示的标题内容
          callback: function () {
            setTimeout(function () {
              console.log("下拉刷新······");
              that.more = false;
              that.title = "";
              that.pageNo = 1;
              that.readyInfo();
              mui(".mui-scroll-wrapper").pullRefresh().endPulldownToRefresh();
            }, 1000);
          },
        },
        up: {
          height: 50, // 可选.默认50.触发上拉加载拖动距离
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentrefresh: "正在加载...", // 可选，正在加载状态时，上拉加载控件上显示的标题内容
          contentmore: "没有更多数据了", // 可选，请求完毕若没有更多数据时显示的提醒内容；
          callback: function () {
            setTimeout(function () {
              that.load_more();
              mui(".mui-scroll-wrapper")
                .pullRefresh()
                .endPullupToRefresh(that.more); // more=true 表示没有更多数据了，无法再加载， more=false，还可以上拉加载
            }, 1500);
          },
        },
      },
    });
  },
  created() {
    var that = this;
    that.token = that.$route.query.token;
    that.$util.setStore("user_token", that.token);
    console.log(" that.token", that.token);
    this.readyInfo();
  },
  methods: {
    onSubmitSysDrug() {
      console.log("点击列表", this.sysDrugItem);
      try {
        appClient.getSysDrugInfo(this.sysDrugItem);
      } catch (e) {
        this.mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    search() {
      this.pageNo = 1;
      this.more = false;
      this.readyInfo();
    },

    //点击选择疾病
    diseaseInfo(item) {
      var mui = this.mui;
      console.log("点击列表", item);
      this.sysDrugList.push(item.title)
      var newArr = [];
      for (var i = 0; i < this.sysDrugList.length; i++) {
        for (var j = i + 1; j < this.sysDrugList.length; j++) {
          if (this.sysDrugList[i] == this.sysDrugList[j]) {
            ++i;
          }
        }
        newArr.push(this.sysDrugList[i]);
      }
      // newArr.join(',')
      this.sysDrugList = newArr
      this.sysDrugItem = newArr.join(',')
      console.log(this.sysDrugItem);
    },

    deleteTag(index) {
      console.log("index", index);
      this.sysDrugList.splice(index, 1);
    },
    async readyInfo() {
      try {
        var that = this;
        var params = {
          title: this.title,
          pageNo: this.pageNo,
          pageSize: that.pageSize,
        };
        let res = await sysDrug(params);
        if (res.success) {
          that.info = res.result.records;
          this.total = res.result.total;
          if(that.info.length<=0){
            this.has_data = true
          }else{
            this.has_data = false
          }
          // this.pageNo = res.result.pageNo;
          // if (type == "more") {
          //   that.info = [...that.info, ...res.result.records];
          //   this.pageNo = res.result.pageNo;
          // } else if (type == "") {
          //   that.info = res.result.records;
          // }
        } else {
          console.log("请求失败");
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 上拉加载
     */
    load_more() {
      var that = this;
      if (that.pageNo * that.pageSize <= this.total) {
        that.pageNo++;
        that.more = false;
      } else {
        that.more = true;
      }
      that.readyInfo("more");
    },

  },
};
</script>

 <style lang="scss">
div {
  /* width: 100%; */
  /* height: 100%; */
  background-color: #fff;
}
.mui-content {
  padding: 0px !important;
  background-color: #fff !important;
}
.mui-card {
  box-shadow: none !important;
}
.mui-bar-nav {
  box-shadow: none !important;
}
.diseaseCode_tenter {
  margin-top: 60px;
}
.mui-btn-outlined {
  width: 60px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  position: absolute;
  right: 15px;
  padding: 0px;
}

.search_btn {
  border: none;
  top: 9px;
  right: 10px;
  font-size: 14px !important;
  /* background-color: #fafafa; */
  z-index: 100;
  color: #808080;
  margin-right: 10px;
  padding: 0;
}
.search_input {
  height: 35px !important;
  line-height: 40px !important;
  background-color: #fafafa !important;
  border-radius: 5px !important;
  border: none !important;
  z-index: 100;
  color: #808080;
  margin-bottom: 0px !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #808080;
  font-size: 14px;
}
.mui-icon-search:before {
  content: "\e466";
  font-size: 22px;
}
.mui-input-row.mui-search .mui-icon-clear {
  /* top: 10px; */
  display: none;
}
.search_box {
  height: 40px;
  margin-bottom: 10px;
}
.search_beader {
  height: 50px;
}
.no-data {
  margin-top: 50px !important;
}
.mui-navigate-right {
  white-space: normal !important;
  padding-right: 25px !important;
}
.mui-control-item {
  touch-action: none;
  width: 100% !important;
  font-size: 18px;
  background: #fff;
  color: #353535 !important;
}
.mui-bar {
  position: static !important;
  background: #fff !important;
  border-bottom: 1px solid #eee !important;
}
.mui_label {
  display: flex;
  align-items: center;
}
.mui_label input {
  margin-right: 5px;
}

.mui_btn_header {
  position: absolute;
  right: 5px;
  top: 6px;
  width: 44px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  font-size: 12px;
  color: #fff;
  border: none;
}

.mui-table-view-cell:after {
  right: 15px !important;
  background-color: #e4e4e4 !important;
}

/* 标签 */
.mui_tag_ul {
  margin: 0 10px;
}
.tag_item {
  display: inline-block;
  line-height: 25px;
  height: 25px;
  font-size: 14px;
  padding: 0 5px;
  border: 1px solid #007aff;
  border-radius: 7px;
  color: #007aff;
  margin-right: 5px;
  margin-bottom: 5px;
}
.btn_submit{
  position: absolute;
  right: 30px;
  bottom: 85px;
  width: 44px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  font-size: 12px;
  color: #fff;
  border: none;
  z-index: 99;
}
.del_btn{
  top: 2px;
  right: -3px;
  border: none;
  padding: 0;
}
</style>
