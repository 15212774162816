<template>
  <div class="mui-content infoDetails">
    <div class="header_img" @click="uploadPicture('avatar')">
      <img
        class="thumb"
        v-if="avatarText || headImageUrl"
        :src="avatarText || headImageUrl"
        alt
      />
      <img
        class="thumb"
        v-else
        src="../../assets/img/default-avatar-patient.png"
        alt
      />
    </div>
    <div class="mui-card">
      <form class="mui-input-group">
        <div class="mui-input-row">
          <label>姓名</label>
          <input
            type="text"
            class="mui-text-right"
            v-model="name"
            placeholder="请输入姓名"
            maxlength="20"
          />
        </div>
        <div class="mui-input-row">
          <label>手机号</label>
          <input
            type="number"
            v-model="mobile"
            disabled="disabled"
            class="mui-text-right"
            placeholder="请输入手机号"
          />
        </div>
        <div class="mui-input-row">
          <label>身份证号码</label>
          <input
            type="text"
            @input="cardWatch()"
            v-model="identityCard"
            class="mui-text-right"
            placeholder="请输入身份证号码"
          />
          <!-- @blur 失去焦点 -->
          <!-- @input 值改变时监听 -->
        </div>
      </form>
    </div>
    <div v-if="autoFillProfile">
      <h5 class="mui-content-padded mui-text-left">
        以下信息自动填充，无需手工填写
      </h5>
      <div class="mui-card">
        <form class="mui-input-group">
          <div class="mui-input-row">
            <!-- @click="showFormatPicker" -->
            <li class="mui-table-view-cell">
              出生日期
              <span class="mui-badge mui-text-right birthday">{{
                birthday
              }}</span>
            </li>
          </div>
        </form>
        <form class="mui-input-group">
          <div class="mui-input-row mui-radio">
            <label>男</label>
            <input name="gender" v-model="gender" value="1" type="radio" />
          </div>
          <div class="mui-input-row mui-radio">
            <label>女</label>
            <input
              name="gender"
              v-model="gender"
              value="2"
              type="radio"
              checked=""
            />
          </div>
        </form>
      </div>
    </div>
    <div class="mui-button-row">
      <button
        type="button"
        :style="`backgroundColor:${$themeColor}`"
        class="mui-btn updatePwd_btn"
        @tap="submit"
      >
        提交
      </button>
    </div>
  </div>
</template>

<script>
import { getSickInfo, upEditInfo, upEditInfoAvatar } from "@/api/index.js";
import util from "@/utils";

export default {
  name: "profile",
  data() {
    return {
      info_token: {},
      name: "",
      card: "",
      patientCard: "",
      mobile: "",
      birthday: "",
      token: "",
      upload_type: "avatar",
      type: "",
      headImageUrl: "",
      defaultIcon: require("../../assets/img/img.jpg"),
      avatarText: "",
      avatar: "",
      gender: "",
      identityCard: "",
      updateType: "",
      autoFillProfile: false,
      colNum: 2,
    };
  },
  components: {},
  created() {
    var that = this;
    if (that.$route.query.type) {
      that.type = that.$route.query.type;
    }
    that.readyInfo();
  },
  mounted() {
    var self = this;
    window.uploadPictureSuccessNative = self.uploadPictureSuccessNative;
  },
  methods: {
    /**
     * 截取身份证信息
     */
    cardWatch() {
      var mui = this.mui;
      if (this.identityCard != "" && this.identityCard.length == 15) {
        const year = `19${this.identityCard.substr(6, 2)}`;
        const month = this.identityCard.substr(8, 2);
        const day = this.identityCard.substr(10, 2);

        var yearReg = /^(19|20)\d{2}$/;
        if (!yearReg.test(year)) {
          // mui.toast("身份证号码·年份·输入有误，无法读取生日", {
          //   duration: "long",
          //   type: "div"
          // });
          return;
        }
        if (month > 12) {
          // mui.toast("身份证号码·月份·输入有误，无法读取生日", {
          //   duration: "long",
          //   type: "div"
          // });
          return;
        }
        if (day > 31) {
          // mui.toast("身份证号码·日期·输入有误，无法读取生日", {
          //   duration: "long",
          //   type: "div"
          // });
          return;
        }
        const gender_code = this.identityCard.substr(14, 1);
        if (gender_code % 2 == 0) {
          this.gender = "2";
        } else {
          this.gender = "1";
        }
       
        this.birthday = `${year}-${month}-${day}`;
        console.log(this.birthday, " this.birthday");
        this.autoFillProfile = true;
      } else if (this.identityCard != "" && this.identityCard.length == 18) {
        const year = this.identityCard.substr(6, 4);
        const month = this.identityCard.substr(10, 2);
        const day = this.identityCard.substr(12, 2);

        let yearReg = /^(19|20)\d{2}$/;
        if (!yearReg.test(year)) {
          mui.toast("身份证号码·年份·输入有误，无法读取生日", {
            duration: "long",
            type: "div",
          });
          return;
        }
        if (month > 12 || month == 0) {
          mui.toast("身份证号码·月份·输入有误，无法读取生日", {
            duration: "long",
            type: "div",
          });
          return;
        }
        if (day > 31 || day == 0) {
          mui.toast("身份证号码·日期·输入有误，无法读取生日", {
            duration: "long",
            type: "div",
          });
          return;
        }
        const gender_code = this.identityCard.substr(16, 1);
        if (gender_code % 2 == 0) {
          this.gender = "2";
        } else {
          this.gender = "1";
        }

        this.birthday = `${year}-${month}-${day}`;
        console.log(this.birthday, " this.birthday");
        this.autoFillProfile = true;
      } else {
        this.gender = "";
        this.birthday = "";
        this.autoFillProfile = false;
      }
    },
    checkIDCard(idcode) {
      // 加权因子
      var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验码
      var check_code = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

      var code = idcode + "";
      var last = idcode[17]; //最后一个

      var seventeen = code.substring(0, 17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      var arr = seventeen.split("");
      var len = arr.length;
      var num = 0;
      for (var i = 0; i < len; i++) {
        num = num + arr[i] * weight_factor[i];
      }

      // 获取余数
      var resisue = num % 11;
      var last_no = check_code[resisue];

      // 格式的正则
      // 正则思路
      /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
      var idcard_patter =
        /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      // 判断格式是否正确
      var format = idcard_patter.test(idcode);

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      return last === last_no && format ? true : false;
    },
    showFormatPicker() {
      if (!this.formatPicker) {
        this.formatPicker = this.$createDatePicker({
          title: "选择出生日期",
          min: new Date(1900, 7, 8),
          max: new Date(2020, 9, 20),
          value: new Date(),
          format: {
            year: "YYYY年",
            month: "MM月",
            date: "DD日",
          },
          onSelect: this.selectHandle,
          onCancel: this.cancelHandle,
        });
      }

      this.formatPicker.show();
    },
    selectHandle(date, selectedVal, selectedText) {
      this.birthday = `${selectedText.join("")}`;
    },
    cancelHandle() {
      this.$createToast({
        type: "correct",
        txt: "取消成功",
        time: 1000,
      }).show();
    },

    //初次获取信息
    async readyInfo() {
      try {
        var that = this;
        var params = {};
        let res = await getSickInfo(params);
        if (res.success) {
          that.name = res.result.name;
          that.mobile = res.result.mobile;
          that.identityCard = res.result.identityCard;
          that.birthday = res.result.birthday;
          that.avatar = res.result.avatar;
          that.avatarText = res.result.avatarText;
          that.headImageUrl = res.result.headImageUrl;
          if (res.result.gender != null) {
            that.gender = res.result.gender.toString();
          }
          if (that.birthday || that.gender) {
            this.autoFillProfile = true;
          }
          this.info_token = res.result;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     *提交
     */
    submit() {
      var that = this;
      var mui = this.mui;
      if (that.name == "") {
        mui.toast("姓名不能为空", { duration: "long", type: "div" });
        return;
      }
      console.log("that.name", that.name);
      var reg_name = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
      if (!reg_name.test(that.name)) {
        mui.toast("姓名只能输入中文，最小2字符，最大20字符", {
          duration: "long",
          type: "div",
        });
        return;
      }
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(that.identityCard)) {
        mui.toast("身份证号码有误", { duration: "long", type: "div" });
        return;
      }
      var regCard =
        /^[1-9]\d{5}((((19|[2-9][0-9])\d{2})(0?[13578]|1[02])(0?[1-9]|[12][0-9]|3[01]))|(((19|[2-9][0-9])\d{2})(0?[13456789]|1[012])(0?[1-9]|[12][0-9]|30))|(((19|[2-9][0-9])\d{2})0?2(0?[1-9]|1[0-9]|2[0-8]))|(((1[6-9]|[2-9][0-9])(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))0?229))\d{3}[0-9Xx]$/;
      if (!regCard.test(that.identityCard)) {
        mui.toast("身份证号码有误", { duration: "long", type: "div" });
        return;
      }
      const year = this.identityCard.substr(6, 4);
      const month = this.identityCard.substr(10, 2);
      const day = this.identityCard.substr(12, 2);

      var yearReg = /^(19|20)\d{2}$/;
      if (!yearReg.test(year)) {
        mui.toast("身份证号码·年份·输入有误，无法读取生日", {
          duration: "long",
          type: "div",
        });
        return;
      }
      if (month > 12 || month == 0) {
        mui.toast("身份证号码·月份·输入有误，无法读取生日", {
          duration: "long",
          type: "div",
        });
        return;
      }
      if (day > 31 || day == 0) {
        mui.toast("身份证号码·日期·输入有误，无法读取生日", {
          duration: "long",
          type: "div",
        });
        return;
      }
      // if (this.gender != 1 && this.gender != 2) {
      //   mui.toast("性别不能为空", {
      //     duration: "long",
      //     type: "div",
      //   });
      //   return;
      // }
      if (that.birthday == "") {
        mui.toast("出生日期不能为空", { duration: "long", type: "div" });
        return;
      }
      var params = {
        name: that.name,
        identityCard: that.identityCard,
        gender: that.gender,
        birthday: that.birthday, 
        // avatar: that.avatar
        avatar: that.avatar,
      };
      upEditInfo(params).then((res)=>{
        if (res.success) {
          mui.toast(res.message, { duration: "long", type: "div" });
          setTimeout(() => {
           appClient.exitPage();
          }, 1500);
        }else{
          mui.toast(res.message, { duration: "long", type: "div" });
        }
      })
      // upEditInfo(params).then(that.upSubInfoHandle);
    },
    upSubInfoHandle(res) {
      var that = this;
      var mui = this.mui;
      if (res.success) {
        mui.toast(res.message, { duration: "long", type: "div" });
        if (
          this.type &&
          this.type == "register" &&
          this.updateType !== "avatar"
        ) {
          //判断是否是登录过来的
          that.logAccessToken();
        } else {
          getSickInfo().then((res) => {
            if (res.success) {
              that.name = res.result.name;
              that.mobile = res.result.mobile;
              that.avatar = res.result.avatar;
              that.avatarText = res.result.avatarText;
              that.headImageUrl = res.result.headImageUrl;
              this.info_token = res.result;

            }
          });
          that.updateType = "";
        }
      } else {
        mui.toast(res.message, { duration: "long", type: "div" });
      }
    },
    uploadPicture() {
      const mui = this.mui;
      try {
        console.log("uploadPicture:", "appClient调用开始");
        appClient.selectImage("avatar");
      } catch (e) {
        console.log("出现错误");
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },

    /**
     * 原生交互--给app传值
     * @param tip
     * ios：https://www.jianshu.com/p/426f62f8d1ab
     */
    logAccessToken() {
      console.log("登录");
      const mui = this.mui;
      console.log(this.info_token, " this.info_token");
      try {
        //  appClient.accessToken( "im_accid", "im_token", "mobile", "password");
        appClient.accessToken(
          this.info_token.imAccid,
          this.info_token.imToken,
          this.info_token.mobile,
          util.getStore("user_token")
        );
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },

    /**
     *      * app调用上传图片回调

     * @param data
     * @param imageUrl
     */
    uploadPictureSuccessNative(data, imageUrl) {
      var that = this;
      console.log("uploadPictureSuccess->data:", data);
      console.log("uploadPictureSuccess->imageUrl:", imageUrl);
      that.updateType = "avatar";
      that.avatar = imageUrl;
      var params = {
        name: that.name,
        identityCard: that.identityCard,
        gender: that.gender,
        birthday: that.birthday,
        avatarUrl: that.avatar,
      };
      upEditInfoAvatar(params).then(that.upSubInfoHandle);
    },
  },
};
</script>
 
 <style lang="scss">
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dddddd;
  font-size: 14px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dddddd;
  font-size: 14px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dddddd;
  font-size: 14px;
}

input::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dddddd;
  font-size: 14px;
}

.infoDetails {
  margin-top: 30px;
  font-size: 14px;
}

.mui-input-clear {
  width: 60% !important;
  text-align: right;
  padding-right: 30px !important;
}

.header_img {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 100px;
  height: 100px;
}

.thumb {
  position: relative;
  border-radius: 50%;
  box-shadow: 1px 4px 6px #4444;
  /* left: 40%; */
  top: 0%;
  width: 100%;
  height: 100%;
}

.infoDetails .border-bottom-1px:after {
  border: none;
}
.infoDetails .border-top-1px::before {
  border: none;
}

.birthday {
  width: auto !important;
}
.updatePwd_btn {
  width: 80% !important;
  height: 40px !important;
  display: block !important;
  margin: 0 auto !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  border: none !important;
  color: #fff !important;
}
</style>
