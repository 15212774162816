<template>
  <div class="forget">
    <form class="mui-input-group">
      <div class="mui-input-row">
        <label>手机</label>
        <input
          type="number"
          v-model="mobile"
          class
          placeholder="请输入手机号"
        />
      </div>
      <div class="mui-input-row">
        <label>验证码</label>
        <input
          type="number"
          class="code-input"
          v-model="captcha"
          placeholder="请输入验证码"
        />
        <button
          type="button"
           :style="`backgroundColor:${$themeColor}`"
          class="btn"
          :disabled="sendCodeBtnDisable"
          @click="sendPhoneCode"
        >
          {{ code_tip }}
        </button>
      </div>
      <div class="mui-input-row">
        <label>新密码</label>
        <input type="password" v-model="newPassword" placeholder="请输入密码" />
      </div>
      <div class="mui-input-row">
        <label>确认新密码</label>
        <input
          type="password"
          v-model="repeatPassword"
          placeholder="确认新密码"
        />
      </div>
    </form>

    <div class="mui-button-row">
      <button
        type="button"
         :style="`backgroundColor:${$themeColor}`"
        class="mui-btn updatePwd_btn"
        @click="submitHandle"
      >
        提交
      </button>
    </div>
  </div>
</template>

<script>
import { forgetPassword, getCaptcha } from "@/api/index.js";
export default {
  name: "alarmMessageDetail",
  data() {
    return {
      mobile: "",
      captcha: "",
      newPassword: "",
      repeatPassword: "",
      code_time: 60,
      code_tip: "发送验证码",
      sendCodeBtnDisable: false,
    };
  },
  components: {},
  methods: {
    go(url) {
      this.$router.push({
        path: url,
      });
    },
    /**
     * 提交忘记密码
     */
    submitHandle(userInfo) {
      const that = this;
      const mui = this.mui;
      if (that.mobile === "") {
        mui.toast("手机号不能为空");
        return;
      }
      if (that.captcha === "") {
        mui.toast("验证码不能为空");
        return;
      }
      if (that.newPassword === "") {
        mui.toast("新密码不能为空");
        return;
      }
      
      var reg_pwd = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{8,}$/;
      if (!reg_pwd.test(this.newPassword)) {
        mui.toast("密码至少八位，需要包含大小写字母、数字、符号至少两种以上组合");
        return;
      }

      if (that.repeatPassword === "") {
        mui.toast("确认密码不能为空");
        return;
      }
      if (that.newPassword != that.repeatPassword) {
        mui.toast("两次输入密码不一致");
        return;
      }
      var params = {
        mobile: that.mobile,
        password: that.newPassword,
        captcha: that.captcha,
      };
      forgetPassword(params).then(that.loginHandle);
    },
    loginHandle(res) {
      const that = this;
      const mui = this.mui;
      const result = res;
      if (result.success) {
        mui.toast(res.message);
        setTimeout(() => {
          try {
            appClient.toLogin(); //跳转到登录页
          } catch (e) {
            mui.toast(
              "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
            );
          }
        }, 1500);
      } else {
        mui.toast(res.message);
      }
    },
    /*
     * 发送验证码
     * */
    sendPhoneCode: function () {
      const that = this;
      // 注册绑定模式: "0", 登录验证:"1", 修改密码模式: "2" 修改手机号3
      const params = {
        smsMode: "2",
        mobile: that.mobile,
      };
      getCaptcha(params).then(that.codeHandle);
    },
    codeHandle(res) {
      const that = this;
      const mui = this.mui;
      const result = res;
      if (result.success) {
        that.countDown();
        mui.toast(result.message);
        that.sendCodeBtnDisable = true;
      } else {
        that.sendCodeBtnDisable = false; //错误的情况下 解除禁止
        mui.toast(result.message);
      }
    },
    /*
     * 倒计时
     *
     */
    countDown() {
      const that = this;
      const timer = setInterval(() => {
        that.code_tip = this.code_time + "s";
        that.code_time--;
        if (that.code_time <= 0) {
          clearInterval(timer);
          that.sendCodeBtnDisable = false;
          that.code_tip = "获取验证码";
          that.code_time = 60;
        }
      }, 1000);
    },
  },
  created() {
    var that = this;
  },
};
</script>

 <style lang="scss">
.forget {
  margin-top: 50px;
}

.forget .page {
  height: 80%;
}

.forget .text-margin {
  margin: 0 10px;
  font-size: 12px;
}

.forget .agreeRadio-span {
  font-size: 12px;
}
.forget .code-input {
  width: 35% !important;
  float: left !important;
}

.forget .agreeRadio {
  width: 10% !important;
}
/* .mainTextColor{
    color: #007AFF!important;
  } */
   .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
.btn{
  color: #fff;
}
</style>
