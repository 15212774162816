// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import util from "@/utils";
import NProgress from 'nprogress'

NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });
import '@/assets/css/common.css'

// mui
import '@/assets/css/mui.min.css';
import mui from '@/assets/js/mui.js'


const isDebug = process.env.VUE_APP_IS_DEBUG;
const themeColor = process.env.VUE_APP_COLOR_NAME;
import store from './store/index.js'
Vue.prototype.$store = store
Vue.prototype.$themeColor = themeColor

Vue.prototype.mui = mui;
Vue.config.productionTip = false;
//调试日志
import VConsole from 'vconsole';
if (isDebug === "true") {
  console.log(isDebug);
  const vConsole = new VConsole();
  Vue.use(vConsole);
}


Vue.prototype.$util = util;

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  console.log('main.to', to);
  var token = to.query.token;
  if ( token !== undefined ) {
    Vue.prototype.$util.setStore( "user_token", token );
  }
  if (to.path === '/login') {
    next();
  }
 /* else if(to.path === '/pdf'){
    next('/prescription') // 兼容原来的报告详情页面

  }*/
  else {
    if (to.matched.length === 0) {
      next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
    }
    next()
    //   if(to.meta.requiresAuth)
    //   {
    //     next({
    //       path: '/login'
    //     })
    //   }
    //   else { next() }
  }
});
router.afterEach(() => {
  NProgress.done();
  // if (util.checkSupportBrowser() === false){//新版本
  //
  // }else{
  //
  // }

});
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  data: {
    eventHub: new Vue()
  },
  components: { App },
  template: '<App/>',
  /** 预渲染*/
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
});
