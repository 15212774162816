<template>
<div class="mui-scroll-wrapper">
    <div class="mui-scroll">
      <div class="acidDiv" v-if="orderList.length > 0">
        <ul class="mui-table-view acidUl" v-for="item in orderList" :key="item.id">
          <li class="">预约项目：{{item.orderProject}}</li>
          <li class="">预约人： {{item.caseName}}</li>
          <li class="">创建日期：{{item.createTime}}</li>
          <p class="check" @tap="ckeckDetail(item)">查看详情</p>
        </ul>
      </div>
      
      <div class="empty" v-else>
        <img src="../../assets/img/empty.png" alt />
        <div class="empty_text">暂时没有数据了</div>
      </div>
  </div>
</div>
</template>

<script>
import {
  getdetection,
} from "@/api/index.js";
export default {
  components: {},
  name: "detectionList",
  data() {
    return {
      pageSize: 15,
      pageNum: 1,
      more: false,
      orderList:{
        records:[]
      },
      id:'',
      // url: '/member/hisOrderBookExamine/list',
    };
  },
  mounted() {
    var mui = this.mui;
    var that = this;
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });

    for(var i = mui.hooks.inits.length-1,item;i>=0;i--){
      item=mui.hooks.inits[i];
      if(item.name=="pullrefresh"){
      item.repeat=true;
      }
    }
    // 下拉刷新、上拉加载
    mui.init({
      pullRefresh: {
        container: ".mui-scroll-wrapper",
        down: {
          height: 50, // 可选,默认50.触发下拉刷新拖动距离,
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentdown: "下拉可以刷新", // 可选，在下拉可刷新状态时，下拉刷新控件上显示的标题内容
          contentover: "释放立即刷新", // 可选，在释放可刷新状态时，下拉刷新控件上显示的标题内容
          contentrefresh: "正在刷新...", // 可选，正在刷新状态时，下拉刷新控件上显示的标题内容
          callback: function () {
            setTimeout(function () {
              console.log("下拉刷新······");
              that.more = false;
              that.pageNum = 1;
              that.getdetectionList();
              mui(".mui-scroll-wrapper").pullRefresh().endPulldownToRefresh();
            }, 1000);
          },
        },
        up: {
          height: 50, // 可选.默认50.触发上拉加载拖动距离
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentrefresh: "正在加载...", // 可选，正在加载状态时，上拉加载控件上显示的标题内容
          contentmore: "没有更多数据了", // 可选，请求完毕若没有更多数据时显示的提醒内容；
          callback: function () {
            setTimeout(function () {
              console.log("上拉加载······");
              that.load_more();
              mui(".mui-scroll-wrapper")
                .pullRefresh()
                .endPullupToRefresh(true|false); // more=true 表示没有更多数据了，无法再加载， more=false，还可以上拉加载
            }, 1500);
          },
        },
      },
    });
  },
  created() {
    this.getdetectionList();
  },
  methods: {
    async getdetectionList () {
      try {
        var that = this;
        var params = {
          pageNum:this.pageNum,
          pageSize:this.pageSize,
        };
        let res = await getdetection(params).then();
        console.log(res);
        if (res.success) {
          that.orderList = res.result.records;
          
        }
      } catch (error) {
        console.log(error);
      }
    },
    ckeckDetail(item){ 
      if (item.id) {
        this.$router.push({
          path: '/addExamineOrderDetail',
          query: {         //参数携带方式
          id: item.id
        }
        });
      }
    },
    /**
     * 上拉加载
     */
    load_more: function () {
      var that = this;
      that.pageNum = that.pageNum + 1;
      var params = {
        // pageNum: this.pageNum,
        // pageSize: that.pageSize,
      };
    },
    /*加载更多*/
    moreHandle: function (res) {
      console.log(res)
      var that = this;
      if (res.success) {
        //正确
        var result = res.data;
        var old_list = that.orderList;
        var new_list = result.data;
        that.orderList = old_list.concat(new_list);
        if (that.pageNum * that.pageSize <= result.total) {
          that.more = false;
        } else {
          that.more = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.orderList {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.search_btn {
  border: none;
  top: 3px;
  right: 10px;
  font-size: 16px !important;
  background-color: #1999da;
  color: #ffffff;
  z-index: 100;
}
.search_input {
  height: 30px !important;
  line-height: 30px !important;
  background-color: #e5e5e5 !important;
  border-radius: 15px !important;
  border: none !important;
  z-index: 101;
}
.mui-bar .mui-input-row .mui-input-clear ~ .mui-icon-clear {
  display: none;
}
.search_beader {
  height: 40px;
}
.no-data {
  margin-top: 50px !important;
}
.empty {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 30%;
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.empty img {
  width: 100%;
  height: 100%;
}
.empty_text {
  position: relative;
  top: -15px;
}


#app{
  background: #fff;
}
#app .acidDiv{
  width: 100%;
  margin-top: 10px;
}
#app .acidUl{
  position: relative;
  padding: 10px 15px 20px;
  /* border-top: 1px solid #d7d7d7; */
}
#app .acidUl li:nth-child(1){
  font-size: 16px;
  line-height: 25px;
  color: #333;
}
#app .acidUl li{
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
}
#app .check{
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 12px;
  // background: $theme-color;
  color: #fff;
  border-radius: 5px;
  padding: 1px 5px;
}
</style>
