<template name="page-empty">
  <div class="empty-layout">
    <div class="empty-layout-icon">
      <img src="../../assets/img/empty.png" />
    </div>
    <div class="empty-title">{{title}}</div>
  </div>
</template>
<script>
export default {
  name: "page-empty",
  components: {},
  props: {
    image_url: {
      type: String
    },
    title: {
      type: String,
      default: "当前无信息"
    }
  }
};
</script>
<style>
.empty-layout {
  width: 100%;
  height: 100%;
  text-align: center;
}
.empty-layout-icon {
  width: 100%;
}
.empty-layout-icon img {
  padding-top: 20%;
  width: 60px;
}
.empty-title {
  color: #606266;
  font-size: 14px;
}
</style>
