<template>
  <div class="agreement mui-scroll-wrapper" style="height: 100%; overflow: scroll">
    <div class="mui-scroll">
      <div class="mui-content">
        <div class="mui-card">
          <div v-html="info.content" class="mui-card-content-inner text-margin mui-scroll"></div>
        </div>
        <div class="mui-button-row">
          <button type="button" :disabled="isDisable"  :style="`backgroundColor:${$themeColor}`" class="mui-btn updatePwd_btn" @click="agreeHandle">
            {{ code_tip }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAgreemen } from "@/api/index.js";
export default {
  name: "alarmMessageDetail",
  data () {
    return {
      info: {},
      windowHeight: "100%",
      isDisable: true,
      code_time: 15,
      code_tip: '已同意相关的协议'
    };
  },
  components: {},
  created () {
    var mui = this.mui;
    var that = this;
    that.readyInfo();
    mui.init({
      swipeBack: true, //启用右滑关闭功能
    });
    mui(".mui-scroll-wrapper").scroll({
      indicators: true, //是否显示滚动条
    });
  },
  methods: {
    /**
     * 同意协议
     */
    agreeHandle () {
      this.logout();
    },
    /**
     * 原生交互
     * @param tip
     * ios：https://www.jianshu.com/p/426f62f8d1ab
     */
    logout () {
      const mui = this.mui;
      try {
        appClient.agree();
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    /**
     * 获取协议内容
     * 7为知情同意书 8为注册协议 9为注销协议
     */
    async readyInfo () {
      try {
        var that = this;
        var params = {
          type: "patient_know",
        };
        let res = await getAgreemen(params).then(that.readyInfoHandle);
        if (res.success) {
          that.info = res.result.records[0];
          that.countDown();
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
   * 倒计时
   *
   */
    countDown () {
      const that = this;
      const timer = setInterval(() => {
        that.code_tip = this.code_time + "s";
        // that.code_time--;
        if (that.code_time-- <= 0) {
          clearInterval(timer);
          that.isDisable = false;
          that.code_tip = "已同意相关的协议";
          that.code_time = 15;
        }
      }, 1000);
    },
  },
};
</script>

 <style lang="scss" scoped>
.agreement .text-margin {
  font-size: 18px;
  text-indent: 1em;
}

.mui-card-content-inner {
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}
 .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
</style>
