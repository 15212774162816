<template>
  <div>
   <!-- <header
      v-if="regionList == ''"
      class="mui-bar mui-bar-nav search_beader"
      style="background-color: #1999da"
    >
      <div
        class="mui-input-row mui-search"
        style="
          width: 70%;
          margin: 5px 20px;
          padding: 0;
          position: absolute;
          z-index: 101;
        "
      >
        <input
          type="text"
          class="mui-input-clear search_input"
          v-model="keyword"
          placeholder="请输入关键字"
        />
      </div>
      <button class="mui-icon mui-pull-right search_btn" @click="search">
        搜索
      </button>
    </header>-->
    <div ref="box" class="mui-scroll-wrapper diseaseCode">
      <!-- MUI内置滑动不能自动回到顶部 -->
      <!-- <div class="mui-scroll"> -->
      <div class="regionList" v-if="regionList != ''">
        <ul
          class="mui-table-view"
          v-for="(item, index) in regionList"
          :key="index"
        >
          <li class="mui-table-view-cell" @tap="selectAddress(item)">
            <a class="mui-navigate-right">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <!--<div
        class="regionList"
        v-if="hospitalList != ''"
        style="padding-top: 40px"
      >
        <ul
          class="mui-table-view"
          v-for="(item, index) in hospitalList"
          :key="index"
        >
          <li class="mui-table-view-cell mui-media" @tap="selectValue(item)">
            <a class="mui-navigate-right">
              <div class="mui-media-body">
                {{ item.name }}
                <p class="mui-ellipsis">
                  {{ item.province_title }}{{ item.city_title
                  }}{{ item.area_title }}
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>-->
      <!-- </div> -->
    </div>
    <div class="empty" v-if="hospitalList == '' && regionList == ''">
      <img src="../../assets/img/empty.png" alt />
      <div class="empty_text">暂时没有数据了</div>
    </div>
  </div>
</template>

<script>
import {
  disease_code,
  getAreaByPid,
  getHospital,
} from "@/api/index.js";
export default {
  components: {},
  name: "regionList",
  data() {
    return {
      regionList: [],
      hospitalList: "",
      has_data: true,
      more: false,
      token: "",
      type: "",
      keyword: "",
      parent_id: "",
      province: "",
      city: "",
      area: "",
      parent_type: 0,
      pageSize: 15,
      pageNum: 1,
    };
  },
  mounted() {
    var mui = this.mui;
    var that = this;
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
    // 下拉刷新、上拉加载
    mui.init({
      pullRefresh: {
        container: ".mui-scroll-wrapper",
        down: {
          height: 50, // 可选,默认50.触发下拉刷新拖动距离,
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentdown: "下拉可以刷新", // 可选，在下拉可刷新状态时，下拉刷新控件上显示的标题内容
          contentover: "释放立即刷新", // 可选，在释放可刷新状态时，下拉刷新控件上显示的标题内容
          contentrefresh: "正在刷新...", // 可选，正在刷新状态时，下拉刷新控件上显示的标题内容
          callback: function () {
            setTimeout(function () {
              console.log("下拉刷新······");
              that.more = false;
              that.keyword = "";
              that.pageNum = 1;
              that.parent_id = "";
              that.parent_type = 0;
              that.readyRegionList();
              mui(".mui-scroll-wrapper").pullRefresh().endPulldownToRefresh();
            }, 1000);
          },
        },
        up: {
          height: 50, // 可选.默认50.触发上拉加载拖动距离
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentrefresh: "正在加载...", // 可选，正在加载状态时，上拉加载控件上显示的标题内容
          contentmore: "没有更多数据了", // 可选，请求完毕若没有更多数据时显示的提醒内容；
          callback: function () {
            setTimeout(function () {
              that.load_more();
              mui(".mui-scroll-wrapper")
                .pullRefresh()
                .endPullupToRefresh(that.more); // more=true 表示没有更多数据了，无法再加载， more=false，还可以上拉加载
            }, 1500);
          },
        },
      },
    });
  },
  created() {
    this.readyRegionList();
  },
  methods: {
    search() {
      this.readyHospital();
    },
    /**
     * 医院点击方法
     */
    selectValue(item) {
      var mui = this.mui;
      // 原PHP所需参数
      // var province_name = item.province_title;
      // var province_id = item.province_id;
      // var city_name = item.city_title;
      // var city_id = item.city_id;
      // var area_name = item.area_title;
      // var area_id = item.area_id;
      // var hospital_name = item.name;
      // var hospital_id = item.id;

      // java 所需参数
      let regionId = item.regionId;
      let hospitalName = item.name;
      let hospitalId = item.id;

      try {
        appClient.getHospital(
          // province_name,
          // province_id,
          // city_name,
          // city_id,
          // area_name,
          // area_id,
          // hospital_name,
          // hospital_id

          // java 所需参数
          regionId,
          hospitalName,
          hospitalId
        );
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    /**
     *地址
     */
    selectAddress(item) {
      var mui = this.mui;
      var that = this;
      that.parent_type = ++that.parent_type;
      console.log("that.parent_type:",that.parent_type);
      if (that.parent_type == 1) {
        that.province = item;
        this.parent_id = item;
        this.readyRegionList();
      } else if (that.parent_type == 2) {
        that.city = item;
        that.regionList = "";
        this.parent_id = item;
        this.readyRegionList();
      } else if (this.parent_type == 3) {
        that.area = item;
        console.log("that.province:",that.province);
        console.log("that.city:",that.city);
        console.log("that.area:",that.area);
      try {
        appClient.selectAddress(
          that.province.title,
          that.city.title,
          that.area.title,
          that.area.id
        );
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
      }

    },
    /**
     * 区域点击方法
     * parent_type 默认为空，点击1次为省，2为市，3为县（区）
     */
    selectHospital(item) {
      var that = this;
      var mui = this.mui;
      that.parent_type = ++that.parent_type;
      if (that.parent_type == 1) {
        that.province = item;

        this.parent_id = item;
        this.readyRegionList();
      } else if (that.parent_type == 2) {
        that.city = item;
        that.regionList = "";

        this.parent_id = item;
        this.readyHospital();
      }
      // else if (this.parent_type == 3) {
      //   that.area = item;
      // }
    },

    /**
     * 获取医院
     */
    async readyHospital() {
      try {
        var that = this;
        var params = {
          cityId: this.city.id,
          name: this.keyword,
        };
        let res = await getHospital(params);
        if (res.success) that.hospitalList = res.result.records;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 获取区域
     */
    async readyRegionList() {
      try {
        var that = this;
        var params = {
          pId: this.parent_id.id || "",
        };
        let res = await getAreaByPid(params);
        if (res.success) that.regionList = res.result;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 上拉加载
     */
    load_more: function () {
      var that = this;
      that.pageNum = that.pageNum + 1;
      var params = {
        pId: this.parent_id.id || "",
        // pageNum: this.pageNum,
        // pageSize: that.pageSize,
      };
      getAreaByPid(params).then(that.moreHandle);
    },
    /*加载更多*/
    moreHandle: function (res) {
      var that = this;
      if (res.success) {
        //正确
        var result = res.data;
        var old_list = that.regionList;
        var new_list = result.data;
        that.regionList = old_list.concat(new_list);
        if (that.pageNum * that.pageSize <= result.total) {
          that.more = false;
        } else {
          that.more = true;
        }
      }
    },
  },
};
</script>

 <style scoped lang="scss">
.regionList {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.search_btn {
  border: none;
  top: 3px;
  right: 10px;
  font-size: 16px !important;
  background-color: #1999da;
  color: #ffffff;
  z-index: 100;
}
.search_input {
  height: 30px !important;
  line-height: 30px !important;
  background-color: #e5e5e5 !important;
  border-radius: 15px !important;
  border: none !important;
  z-index: 101;
}
.mui-bar .mui-input-row .mui-input-clear ~ .mui-icon-clear {
  display: none;
}
.search_beader {
  height: 40px;
}
.no-data {
  margin-top: 50px !important;
}
.empty {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 30%;
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.empty img {
  width: 100%;
  height: 100%;
}
.empty_text {
  position: relative;
  top: -15px;
}
</style>
