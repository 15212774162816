<template>
  <div class="d_Div">
    <ul class="d_Ul"  v-if="info.caseContent_dictText">
      <li>预约项目：{{info.orderProject}}</li>
      <li>所在医院：{{info.hospitalName}}</li>
      <li >出生日期：{{info.caseContent_dictText.birthdate}}</li>
      <li>创建日期：{{info.createTime}}</li>
    </ul>
    <ul class="d_Ul" v-if="info.caseContent_dictText">
      <p> <span></span> 患者信息</p>
      <li>预约人：{{info.caseName}}</li>
      <li>年龄：{{info.caseContent_dictText.age}}</li>
      <li>性别：{{info.caseContent_dictText.genderText}}</li>
      <li>手机号：{{info.mobile}}</li>
      <li>身份证号：{{info.caseContent_dictText.idCard}}</li>
    </ul>
    <ul class="d_Ul" v-if="info.caseContent_dictText">
      <p> <span></span> 病情描述</p>
      <li>药物史：{{info.caseContent_dictText.drugHistory}}</li>
      <li>过敏史：{{info.caseContent_dictText.allergy}}</li>
      <li>手术史：{{info.caseContent_dictText.operationHistory}}</li>
      <li>病情描述：{{info.caseContent_dictText.described}}</li>
      <!-- <li>相关资料：{{info.caseContent_dictText.symptom}}</li> -->
    </ul>
  </div>
</template>

<script>
import { getdetectionDetail } from "@/api/index.js";
export default {
  name: "detectionDetail",
  data() {
    return {
      info: {},
      content: "",
      money: 10,
      messageId: "",
      token: "",
      id: "",
    };
  },
  created() {
    var mui = this.mui;
    this.id = this.$route.query.id;
    console.log(this.id)
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
  },
  methods: {
    /**
     * 初次获取数据
     */
    async readyInfo() {
      
      try {
        const mui = this.mui;
        var params = {
          id: this.id
        };
        
        let res = await getdetectionDetail(params);
        if (res.success) {
          this.info = res.result;
          console.log(this.info, " this.info ");
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

 <style lang="scss" scoped>
#app{
  padding: 10px;
}
#app .d_Div .d_Ul{
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 10px;
  background: #fff;
}

#app .d_Div .d_Ul li{
  font-size: 14px;
  line-height: 25px;
  color: #666;
  margin-bottom: 10px;
}
#app .d_Div .d_Ul p{
  font-size: 16px;
  color:#333;
}
#app .d_Div .d_Ul p span{
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 15px;
  border-radius: 15px;
  background: var(--theme);
}
</style>