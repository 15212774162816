<template>
  <div class="download">
    <div class="download_img">
      <img src="../../assets/img/download2.png" alt />
    </div>
    <div class="download_but">
      <button type="button" class="mui-btn  download-button" @click="andAndriod">Andriod下载</button>
<!--      <button type="button" class="mui-btn updatePwd" @click="iosDown">IOS下载</button>-->
    </div>
  </div>
</template>

<script>
import { getDownload } from "@/api/index.js";
export default {
  name: "alarmMessageDetail",
  data() {
    return {
      readyList: {},
      is_wechat: false
    };
  },
  components: {},
  created() {
    var mui = this.mui;
    var that = this;
    that.readyDown();
    that.isWechat();
  },
  methods: {
    readyDown() {
      var that = this;
      var params = {};
      getDownload(params).then(that.andriodHandle);
    },
    andriodHandle(res) {
      var mui = this.mui;
      var that = this;
      if (res.success) {
        that.readyList = res.result;
        if (that.is_wechat) {
          alert("请点击右上角并用浏览器打开");
        }
      } else {
        mui.toast(res.message);
      }
    },
    /* 下载
     */
    andAndriod() {
      var mui = this.mui;
      var that = this;
      if (that.is_wechat) {
        alert("请点击右上角并用浏览器打开");
      } else {
        var url = that.readyList.uploadUrlText;
        window.open(url);
      }
    },
    /**
     * 判断是否是浏览器
     */
    isWechat() {
      var We = window.navigator.userAgent.toLowerCase();
      if (We.match(/MicroMessenger/i) == "micromessenger") {
        this.is_wechat = true;
      } else {
        this.is_wechat = false;
      }
    },
    iosDown() {
      var mui = this.mui;
      var that = this;
      mui.toast("正在维护中...");
    }
  }
};
</script>

 <style scoped lang="scss">
.download {
  background-image: url("../../assets/img/download1.png");
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
.download_img {
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
  height: 50%;
}
.download_img img {
  z-index: 100px;
  width: 100%;
  height: 100%;
}

.download_but a {
  width: 100%;
}
  .download-button{
    font-weight: 600;
    box-shadow: 1px 3px 6px rgb(228, 223, 223);
    width: 80%;
    height: 40px;
    display: block;
    font-size: 16px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #1899d8;
    border: none !important;
    color: #ffffff;
  }
   .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
</style>
