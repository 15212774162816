<template>
  <div class="messageDetail">
    <div class="mui-card">
      <div v-html="ready_info.msgContent" class="mui-card-content-inner text-margin"></div>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { editReadStatus,messageDetail } from  "@/api/index.js";
export default {
  mixins: [initData],
  components: {},
  data() {
    return {
	anntId: "",
    ready_info: [], 
    };
  },
  computed: {
    title() {
      return "消息详情";
    },
  },
  onLoad(options) {
    this.anntId = options.anntId;
    this.messageDetail();
  },
  methods: {
    async messageDetail() {
      try {
        let params = {
          anntId: this.anntId,
        };
        let res = await messageDetail(params);
        if (res.success) {
          this.ready_info = res.result;
          await editReadStatus(params);

        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.messageDetail{
  background-color: #fff;
  height: 100%;
}
.claimDetials {
  .text-content {
    padding:20upx;
    max-height: 100% !important;
  }
}
</style>
