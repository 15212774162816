<template>
  <div class="prescription-content">
    <div class="title-top">
      {{ info.netHospitalName }}
      <!-- {{ info.netHospitalSignature }} -->
    </div>
    <div class="title-img">
      <template v-if="info._netHospitalSignature != null">
        <img
          :src="info._netHospitalSignature"
          class="icon-image"
          alt="暂未盖章"
        />
        <!-- <img
          src="../../assets/img/default-avatar-doctor.png"
          style="width: 100px; height: 100px"
          alt="暂未盖章"
        /> -->
      </template>
    </div>
    <div class="prescription-title">普 通 处 方</div>
    <div class="prescription-basics">
      <div>处方编号：{{ info.orderNumber }}</div>
      <div style="margin-top: 5px">
        <span style="margin-top: 2%">姓名：{{ caseInfo.name }}</span>
        <span style="margin-left: 10%">性别：{{ caseInfo.genderText }}</span>
        <span style="margin-left: 10%">年龄：{{ caseInfo.age }}岁</span>
      </div>
      <div style="margin-top: 5px">
        <span>开方时间：{{ createTime }}</span>
      </div>
      <div style="margin-top: 5px">
        <span>科室：{{ info.departmentName }}</span>
      </div>
      <div style="margin-top: 5px">
        <span>联系方式：{{ caseInfo.mobile }}</span>
      </div>
      <div style="margin-top: 5px">
        <span>诊断：{{ info.diagnoseResult }}</span>
      </div>
    </div>
    <hr class="prescription-hr" />
    <div class="perscription-medicine">
      <div class="medicine-title">Rp：</div>
      <template v-for="(item, index) in drugInfo">
        <div class="medicine-content" :key="index">
          <div style="margin-top: 5px">
            <template v-if="drugInfo.length <= 1">
              {{ item.drugs_name }}：（{{ item.specs }}）*{{ item.quantity }}
              {{ item.drug_unit }}
            </template>
            <template v-else>
              {{ index + 1 }}：（{{ item.specs }}）* {{ item.quantity }}
              {{ item.drug_unit }}
            </template>
          </div>
          <div style="margin-top: 5px">
            用法：{{ item.from }} &nbsp;&nbsp;{{ item.dosage
            }}{{ item.unit }}&nbsp;&nbsp;{{ item.frequency }}
          </div>
        </div>
      </template>

      <div class="bottom-white">
        <div class="left">
          <hr class="white-hr" />
        </div>
        <div class="center">以下为空白</div>
        <div class="right">
          <hr class="white-hr" />
        </div>
      </div>

      <div class="medicine-withe"></div>
      <div class="medicine-bottom">
        <div class="bottom-money">
          <div class="money-left">
            <!-- <span>开方医生：{{ info.doctorSignature }} <img src="../../assets/img/collect_select.png" alt=""> </span> -->
            <span
              >{{doctorTitle}}:
              <template v-if="info._doctorSignature != ''">
                <img
                  :src="info._doctorSignature"
                  alt="未签字"
                  class="img-doctor"
                />
              </template>
              <template v-else>
                未签字
              </template>
            </span>
          </div>
          <div class="money-right">
           <span>{{moneyType}}:<template  v-if="!info.money=='0.0元'">{{ info.money }}</template></span>
          </div>
        </div>
        <div class="bottom-money">
          <div class="money-left">
            <!-- <span> 审核医生：{{ info.firstPharmacistSignature }} <img src="../../assets/img/collect_select.png" alt="" class="img-doctor"></span> -->
            <span>
              {{firstPHCTitle}}:
              <template v-if="info._firstPharmacistSignature != ''">
                <img
                  :src="info._firstPharmacistSignature"
                  alt="未签字"
                  class="img-doctor"
                />
              </template>
              <template v-else>
                未签字
              </template>
            </span>
          </div>
          <div class="money-right">
            <!-- <span>核对药师：{{ info.secondPharmacistSignature }}</span> -->
            <span>
              {{secondPHCTitle}}:
              <template v-if="info._secondPharmacistSignature != ''">
                <img
                  :src="info._secondPharmacistSignature"
                  alt="未签字"
                  class="img-doctor"
                />
              </template>
              <template v-else>
                未签字
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="prescription-hr" />
    <div class="prescription-bottom">
      处方有效期{{ info.prescriptionValidityTime }}天
    </div>
  </div>
</template>

<script>
import { getPrescription,initConfig } from "@/api/index.js";
export default {
  data() {
    return {
      id: null,
      info: {}, // 处方信息
      caseInfo: {}, //病历信息
      drugInfo: {}, //药品信息
      createTime: "",
         doctorTitle:"",
      firstPHCTitle:"",
      secondPHCTitle:"",
      moneyType: "",
    };
  },
  methods: {
    //全局获取
    getInitConfig(){
    initConfig().then((res)=>{
    if (res.success) {
      console.log("获取数据",res);
      this.moneyType = res.result.pricePHCTitle;//金额
      this.doctorTitle = res.result.doctorTitle;//医生
      this.firstPHCTitle = res.result.firstPHCTitle;//审核药师
      this.secondPHCTitle = res.result.secondPHCTitle;//核对药师
    }
    })
    },
    // 调用接口获取数据
    getPrescriptionInfo() {
      var params = { id: this.id };
      getPrescription(params).then((res) => {
        this.createTime = res.result.createTime;
        this.info = res.result.prescriptionInfoText;
        this.drugInfo = res.result.prescriptionInfoText.arrayDrug;
        this.caseInfo = res.result.caseInfo;
      });
    },
  },
  created() {
    this.getInitConfig()
    const mui = this.mui;
    if (this.$route.query.reportId) {
      this.id = this.$route.query.reportId;
    } else {
      // this.id = 1;
      mui.toast("处方id没有传过来");
      return;
    }
    console.log("this.id", this.id);
    this.getPrescriptionInfo();
  },
};
</script>

<style lang="scss" scoped>
.white-hr {
  height: 1px;
  width: 80%;
  margin-left: 10%;
  border: 0px solid #000;
  background: #000;
}

.white-hr2 {
  width: 80%;
  margin-left: 0%;
  height: 5px;
  border: none;
  border-top: 1px dotted rgb(158, 147, 147);
}
.bottom-white {
  width: 100%;
  height: 40px;
  .left {
    width: 35%;
    height: 100%;
    float: left;
    line-height: 40px;
    padding-top: 18px;
  }
  .center {
    width: 30%;
    height: 100%;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
  }
  .right {
    width: 35%;
    height: 100%;
    float: left;
    line-height: 40px;
    padding-top: 18px;
  }
}
.img-doctor {
  width: 40px;
  height: 20px;
}
.prescription-content {
  width: 95%;
  padding-top: 1px;
  margin-left: 2.5%;
  .prescription-route {
    overflow: hidden;
    width: 100%;
    height: 40px;
    line-height: 40px;
    .perscription-goBack {
      float: left;
      width: 5%;
    }
    .perscription-name {
      float: left;
      width: 95%;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .title-top {
    width: 100%;
    position: relative;
    margin-top: 40px;
    text-align: center;
    color: #000;
    font-size: 18px;
  }
  .title-img {
    position: relative;
    margin-top: -60px;
    text-align: center;
    width: 100px;
    margin-left: 35%;
    height: 100px;
    opacity: 0.6;
    .icon-image {
      width: 100px;
      height: 100px;
    }
  }
  .prescription-title {
    position: relative;
    width: 25%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #000;
    font-size: 16px;
    float: right;
    margin-top: -20px;
    border-radius: 5px;
    z-index: 9999;
  }
  .prescription-basics {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
  }
  .prescription-hr {
    height: 5px;
    border: none;
    border-top: 1px dotted #555;
  }
  .medicine-hr {
    height: 1px;
    width: 100%;
    border: 1px solid #000;
    background: #000;
  }
  .perscription-medicine {
    margin-top: 10px;
    width: 100%;
    .medicine-title {
      font-size: 16px;
      font-weight: 600;
    }
    .medicine-content {
      margin-top: 5px;
      font-size: 14px;
    }
    .medicine-withe {
      width: 100%;
      height: 100px;
    }
    .medicine-bottom {
      width: 100%;
      margin-left: 10px;
      font-size: 14px;
      .bottom-money {
        margin: 10px 0 10px 0;
        overflow: hidden;
        .money-left {
          float: left;
          width: 55%;
        }
        .money-right {
          float: left;
          width: 45%;
        }
      }
    }
  }
  .prescription-bottom {
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
