import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // 
        ckeckVal: false,
        loading_status: false
    },
    mutations: {
        ckeckVal(state, status) {
            console.log('协议状态---/status:', status)
            state.ckeckVal = status
        },
        loading_status(state, status) {
            console.log('loading---/status:', status)
            state.loading_status = status
        },
    }
});

export default store
