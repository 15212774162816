<template>
  <div class="electronicCase">
    <ul class="d_Ul">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 基本信息</p>
      <li>就诊人姓名：{{caseInfo.name}}</li>
      <li>性别：{{caseInfo.gender_dictText}}</li>
      <li>出生日期：{{caseInfo.birthday}}</li>
    </ul>
    <ul class="d_Ul">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 详细描述</p>
      <li>主诉：{{caseInfo.described}}</li>
      <li>现病史：{{caseInfo.patientHistory}}</li>
      <li>既往史：{{caseInfo.medicalHistory}}</li>
      <li>用药史：{{caseInfo.drugHistory}}</li>
    </ul>
    <ul class="d_Ul">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 医生诊断</p>
      <li>诊断及治疗意见：{{caseInfo.treatmentOpinion}}</li>
      <li>备注：{{caseInfo.remark}}</li>
    </ul>
  </div>
</template>

<script>
import { electronicCase } from "@/api/index.js";
export default {
//   name: "detectionDetail",
  data() {
    return {
      caseInfo:{},
      id: "",
    };
  },
  created() {
    var mui = this.mui;
    this.id = this.$route.query.id;
    console.log('this.id', this.id)
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
  },
  methods: {
    /**
     * 初次获取数据
     */
    async readyInfo() {
      
      try {
        const mui = this.mui;
        var params = {
          id: this.id
        };
        
        let res = await electronicCase(params);
        if (res.success) {
          this.caseInfo = res.result;
        //   console.log(" this.info ",  this.info);
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style lang="scss">
  .electronicCase{
    overflow: auto;
    .d_Ul{
      border-radius: 8px;
      background: #fff;
    }
     .d_Ul li{
      font-size: 14px;
      line-height: 25px;
      color: #666;
      margin-bottom: 10px;
    }
     .d_Ul p{
      font-size: 16px;
      color:#333;
    }
    .d_Ul p span{
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 15px;
      border-radius: 15px;
      background: var(--theme);
    }
  }

</style>