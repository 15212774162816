<template>
  <view class="my-message-content">
      <van-pull-refresh
              class="s-list-warrper"
              v-model="refreshing"
              @refresh="onRefresh"
              v-if="ready_list && ready_list.length > 0"
      >
          <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  :immediate-check="false"
                  :offset="50"
                  @load="onLoad"
          >
      <view class="bg-white padding-left-xs text-sm">
        <view
          class="solids-bottom padding-sm flex align-center"
          v-for="(item, index) in ready_list"
          :key="index"
          @tap="myMessageDetail(item)"
        >
          <view class="flex-sub">
            <view class="text-left text-lg desc-cut">
              {{ item.titile }}
            </view>
            <view class="text-right text-gray text-df time-margin">{{
              item.sendTime
            }}</view>
            <view class="cu-tag badge readFlag" v-if="item.readFlag !== '1'"
              >new</view
            >
          </view>
        </view>
      </view>
          </van-list>

      </van-pull-refresh>

    <empty v-else />
  </view>
</template>
<script>
  import Vue from "vue";
  import { List, PullRefresh, Empty } from "vant";
  Vue.use(List);
  Vue.use(PullRefresh);
  Vue.use(Empty);
import initData from "@/mixins/initData";
import { messageList,readAll } from  "@/api/index.js";
import empty from "@/components/common/page-empty";

export default {
  mixins: [initData],
  components: {
    empty,
  },
  data() {
    return {
      messages: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: {
        pageNo: 1,
        pageSize: 6,
        total: "",
      },
      ready_list: [],
      isMore: false,
      scrollHeight: 0,
	  anntId:"",
    };
  },

  created() {
    console.log("sd");
    this.readyList();
  },
  methods: {
    onLoad() {
      this.page.pageNo += 1;
      this.readyList();
    },
    onRefresh() {
      this.ready_list = [];
      this.page.pageNo = 1;
      this.readyList();
    },
    myMessageDetail(item) {
		console.log("item:",item);
      this.$router.push({
        path: '/messageDetail?anntId=' + item.anntId,
        query: {         //参数携带方式
          anntId: item.id
        }
      });
    },

    async readyList() {
      try {
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
        };
        let res = await messageList(params);
        const { result } = res;
        if (res?.success) {
          this.page.total = result.total;
          if (result?.records?.length > 0) {
            this.loading = false;
            this.refreshing = false;
            this.ready_list = [...this.ready_list, ...result.records];
            if (result.records.length < this.page.pageNo) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-message-content {
  .solids-bottom:after {
    border-bottom: 4rpx solid #eee;
  }
}

.time-margin {
  margin-top: 5upx;
}
.readFlag {
  right: 20upx;
  top: 10upx;
}
</style>
