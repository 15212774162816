<template>
  <div class="orderBookItemDetail">
    <ul class="d_Ul" v-if="info">
      <p><span :style="`backgroundColor:${$themeColor}`"></span> 订单信息</p>
      <li class="">化验名称： {{ info.title || "" }}</li>
      <li class="">所属患者： {{ info.patientName || "" }}</li>
      <li class="">所属医院： {{ info.hospital || "" }}</li>
      <li class="" v-if="projectItem.length > 0">
        报告医生： {{ projectItem[0]["bgr"] || "" }}
      </li>
      <li class="" v-if="projectItem.length > 0">
        报告时间： {{ projectItem[0]["shsj"] || "" }}
      </li>
      <!--      <li>参考值：{{info.reference|| ""}}</li>-->
      <li>创建时间：{{ info.createTime }}</li>
    </ul>
    <!--  <ul class="d_Ul" v-if="info && info.result">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 化验结果</p>
      <li>{{info.result|| "-"}}
      </li>
    </ul>-->
    <ul class="d_Ul" v-if="info && info.prompt">
      <p><span :style="`backgroundColor:${$themeColor}`"></span> 提示信息</p>
      <li>{{ info.prompt || "" }}</li>
    </ul>
    <ul class="bar-code">
      <li>
        <svg id="barCode"></svg>
      </li>
    </ul>
    <ul v-if="barCode" class="d_Ul">
     <li>
        条形码：{{barCode||""}}
      </li>
    </ul>
    <!-- <ul class="d_Ul" v-if="info && info.barCodeImg_dictText">
      <li><img :src="info.barCodeImg_dictText" /></li>
    </ul> -->
    <ul class="d_Ul item-body" v-if="projectItem.length > 0">
      <p><span :style="`backgroundColor:${$themeColor}`"></span> 检查项目</p>
      <li v-for="item in projectItem" class="item-body-list">
        <ul class="item-detail">
          <li class="">项目名称： {{ item.itemName || "" }}</li>
          <!--          <li class="">化验医生： {{item.bgr|| ""}}</li>-->
          <!--          <li class="">化验医生： {{item.bgr|| ""}}</li>-->
          <li>化验结果：{{ item.jg || "" }}</li>
          <li>参考值：{{ item.itemReference || "" }}</li>
          <!--          <li>报告时间：{{item.shsj|| ""}}</li>-->
          <li>提示信息：{{ item.itemResultIndicator || "" }}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { laboratoryReport } from "@/api/index.js";
import JsBarcode from "jsbarcode";
export default {
  name: "detectionDetail",
  data() {
    return {
      info: {},
      caseInfo: {},
      projectItem: {},
      content: "",
      money: 10,
      messageId: "",
      token: "",
      id: "",
      barCode: "",
    };
  },
  created() {
    var mui = this.mui;
    this.id = this.$route.query.reportId;
    console.log(this.id);
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值 0.0006
    });
  },
  methods: {
    /**
     * 初次获取数据
     */
    async readyInfo() {
      try {
        const mui = this.mui;
        var params = {
          id: this.id,
        };

        let res = await laboratoryReport(params);
        console.log("获取数据", res);
        if (res.success) {
          this.info = res.result;
          this.barCode = res.result.barCode;
          this.caseInfo = res.result.caseContent_dictText;
          console.log(this.info, " this.info ");
          this.toJsBarcode()
          if (this.info.itemArray) {
            this.projectItem = this.info.itemArray;
          }
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    toJsBarcode(){
    JsBarcode("#barCode", this.barCode, {
      format: "CODE39",
      lineColor: "#000",
      background: "#EBEEF5",
      width: 1,
      height: 40,
      displayValue: false,
      margin: 10, //设置条形码周围的空白边距
    });
    }
  },
};
</script>

<style lang="scss">
.bar-code {
  margin: 0 19%;
}
.orderBookItemDetail {
  overflow: auto;
  padding: 10px;
  .d_Ul {
    border-radius: 8px;
    background: #fff;
  }
  .d_Ul li {
    font-size: 14px;
    line-height: 25px;
    color: #666;
    margin-bottom: 10px;
    word-wrap: break-word;
    word-break: normal;
  }
  .d_Ul p {
    font-size: 16px;
    color: #333;
  }
  .d_Ul p span {
    display: inline-block;
    vertical-align: middle;
    width: 3px;
    height: 15px;
    border-radius: 15px;
    background: var(--theme);
  }
  .item-body {
    .item-body-list {
      background-color: #eee;
      border-radius: 2%;
      padding: 5px;
      .item-detail {
        margin-top: 10px;
        li {
          font-size: 10px;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
</style>