<template>
  <div class="changePassword">
    <div class="mui-content fz-15">
      <form class="mui-input-group">
        <div class="mui-input-row">
          <label>手机</label>
          <input type="number" v-model="mobile" disabled class="phone_input" v placeholder="请输入手机号" />
        </div>
        <div class="mui-input-row">
          <label>验证码</label>
          <input type="number" class="code-input" v-model="captcha" placeholder="请输入验证码" />
          <button
            type="button"
            :style="`backgroundColor:${$themeColor}`"
            class="btn"
            :disabled="sendCodeBtnDisable"
            @click="sendPhoneCode"
          >{{code_tip}}</button>
        </div>
        <div class="mui-input-row verifyCode">
          <label>新密码</label>
          <input type="password" v-model="newPassword" placeholder="请输入新密码" />
        </div>
        <div class="mui-input-row">
          <label>确认密码</label>
          <input type="password" v-model="repassword" placeholder="请再次输入密码" />
        </div>
      </form>
    </div>
    <button type="button" :style="`backgroundColor:${$themeColor}`" class="mui-btn updatePwd_btn" @click="updatePwd">提交</button>
  </div>
</template>
<script>
import { forgetPassword, getSickInfo, getCaptcha } from "@/api/index.js";
export default {
  data() {
    return {
      mobile: "",
      captcha: "",
      repassword: "",
      accessToken: "",
      newPassword: "",
      code_time: 60,
      code_tip: "发送验证码",
      sendCodeBtnDisable: false
    };
  },
  created() {
    // var that = this;
    this.readyInfo();
  },
  methods: {
    /*
     * 发送验证码
     * */
    sendPhoneCode: function() {
      const that = this;
     // 注册绑定模式: "0", 登录验证:"1", 修改密码模式: "2" 修改手机号3
      const params = {
        smsMode: "2",
        mobile: that.mobile
      };
      getCaptcha(params).then(that.codeHandle);
    },
    codeHandle(res) {
      const that = this;
      const mui = this.mui;
      const result = res;
      if (result.success) {
        that.countDown();
        mui.toast(result.message);
        that.sendCodeBtnDisable = true;
      } else {
        that.sendCodeBtnDisable = false; //错误的情况下 解除禁止
        mui.toast(result.message);
      }
    },
    /*
     * 倒计时
     *
     */
    countDown() {
      const that = this;
      const timer = setInterval(() => {
        that.code_tip = this.code_time + "s";
        that.code_time--;
        if (that.code_time <= 0) {
          clearInterval(timer);
          that.sendCodeBtnDisable = false;
          that.code_tip = "获取验证码";
          that.code_time = 60;
        }
      }, 1000);
    },
    updatePwd() {
      var app = this.mui;
      var params = {
        captcha: this.captcha,
        mobile: this.mobile,
        password: this.newPassword
      };
      if (this.newPassword != this.repassword) {
        app.toast("确认密码不一致，请重新核对");
        return;
      }
      if (this.mobile == "") {
        app.toast("手机号不能为空");
        return;
      }
      if (this.captcha == "") {
        app.toast("验证码不能为空");
        return;
      }
        var reg_pwd = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/;
        if (!reg_pwd.test(this.newPassword)) {
          app.toast("密码至少八位，需要包含大小写字母、数字、符号至少三种组合");
          return;
        }
      if (this.repassword == "" || this.newPassword == "") {
        app.toast("密码都不能为空");
        return;
      }
      forgetPassword(params).then(function(res) {
        if (res.success) {
          app.toast(res.message);
          setTimeout(() => {
            try {
              appClient.toLogin(); //跳转到登录页
            } catch (e) {
              app.toast(
                "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
              );
            }
          }, 1500);
        } else {
          app.toast(res.message);
        }
      });
    },
    async readyInfo () {
      try {
        var params = {};
        let res = await getSickInfo(params);
        if (res.success) {
          console.log(res);
          this.user_id = res.result.id;
          this.mobile=res.result.mobile
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>
 <style scoped lang="scss">
.changePassword {
  margin-top: 40px;
}
.code-input {
  width: 35% !important;
  float: left !important;
}
.changePassword .mui-pull-right {
  line-height: 20px;
  font-size: 15px;
}

.changePassword input {
  font-size: 15px;
}
.updatePwd_btn{
  margin-top: 10px !important;
}
.btn{
  color: #fff;
}
.phone_input{
  color: #949394;
}
</style>
