"use strict"; //任何一个工具类加上这个
import axios from "axios";
import store from '@/store'


/**
 * 获取签名 将链接地址的所有参数按字母排序后拼接加上token进行md5
 * url 链接地址
 * date 参数{参数名1 : 值1, 参数名2 : 值2} *
 * token 签名token 非必须
 */
function getAccessToken() {
    // return md5('www.eciyuan.net' + 'l2V|gfZp{8`;jzR~6Y1_');
    // return 'www.eciyuan.net' + 'l2V|gfZp{8`;jzR~6Y1_';
}

/*
 ** randomWord 产生任意长度随机字母数字组合
 ** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
 ** xuanfeng 2014-08-28
 */
function randomWord(randomFlag, min, max) {
    var str = "",
        range = min,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    // 随机产生
    if (randomFlag) {
        range = Math.round(Math.random() * (max - min)) + min;
    }
    for (var i = 0; i < range; i++) {
        var pos = Math.round(Math.random() * (arr.length - 1));
        str += arr[pos];
    }
    return str;
}

/**
 * 签名 SHA1(AppSecret + Nonce)，
 * 三个参数拼接的字符串，进行SHA1哈希计算，转化成16进制字符(String，小写)
 */
/*function getSign() {
    var sha1_result = hex_sha1('a060e77c5a8485aeb061aee5d7f8fa709acb4473' + '123456');
    console.log('sha1_result:', sha1_result);
    return (sha1_result.toString(16)).toLowerCase();
}*/

/**
 * 用户userToken
 * @returns {undefined}
 */
function getUserToken() {
    return getStore('user_token');
}

/**
 * 存储localStorage
 */
function setStore(name, content) {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
function getStore(name) {
    if (!name) return;
    return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
function removeStore(name) {
    if (!name) return;
    window.localStorage.removeItem(name);
}

/**
 * 清除缓存
 */
function clearStorage() {
    window.localStorage.clear();
}

/**
 * 参数处理
 * @param data
 * @returns {string}
 */
function paramHandle(data) {
    var result_json = '';
    var newJson = '';
    var user_token = getStore('user_token');
    if (user_token !== "" && user_token !== null) {
        newJson = {
            user_token: user_token
        };
    }
    if (data !== '' && newJson !== '') {
        result_json = mergeJsonObject(data, newJson);
    } else if (newJson === '') {
        result_json = data;
    } else if (data === '') {
        result_json = newJson;
    }
    return result_json;
}

/**
 * json 合并
 * @param jsonbject1
 * @param jsonbject2
 * @returns {{}}
 */
function mergeJsonObject(jsonbject1, jsonbject2) {
    var resultJsonObject = {};
    var attr = "";
    for (attr in jsonbject1) {
        resultJsonObject[attr] = jsonbject1[attr];
    }
    for (attr in jsonbject2) {
        resultJsonObject[attr] = jsonbject2[attr];
    }
    return resultJsonObject;
}

/*function url(action, querystring) {
    var url = action;
    var param = "";
    var params = "";

    if (querystring && typeof querystring === 'object') {
        for ( param in querystring) {
            if (param && querystring.hasOwnProperty(params) && querystring[param]) {
                url += param + '=' + querystring[param] + '&';
            }
        }
    }
    return url;
}*/

/*function showToast(msg) {
    Vue.$vux.toast.show({
        type: 'text',
        text: msg
    })
}

function showLoading() {
    Vue.$vux.loading.show({
        text: 'Loading'
    });
}*/

/*function hideToast() {
    Vue.$vux.toast.hide()
}

function hideLoading() {
    Vue.$vux.loading.hide()
}*/

/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 *            用url方式表示的base64图片数据
 *             var img = "data:image/jpeg;base64," + imageData;
 */
function convertBase64UrlToBlob(urlData) {

    var arr = urlData.split(',');
    var bytes = window.atob(urlData.split(',')[1]); //去掉url的头，并转换为byte
    //处理异常,将ascii码小于0的转换为大于0
    var mime = arr[0].match(/:(.*?);/)[1];
    var ab = new ArrayBuffer(bytes.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
}
/*
* 图片的绝对路径地址 转换成base64编码 如下代码：
  var image = new Image();
  image.crossOrigin = '';
  image.src = img;
  image.onload = function () {
    var base64 = getBase64Image(image);
    console.log(base64);
  }
*/
function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
}
//**dataURL to blob**
/*function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}*/

/**
 * data:[<mime type>][;charset=<charset>][;base64],<encoded data>
 * * base64  to blob二进制
 */
function dataURItoBlob(base64Data) {
    var byteString;
    if (base64Data.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64Data.split(',')[1]);
    else
        byteString = unescape(base64Data.split(',')[1]);
    var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {
        type: mimeString
    });
}

/**
 *
 * @param {*} url 文件地址不可含有域名
 * @param {*} callback 成功回调函数
 */
/*function fileUrlHandle(url, callback) {
    var fileInfo = {
        fileType: '',
        fileUrl: Host + url
    }
    var imgTest = /\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/
    var videoTest = /\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|ogg|mp4)$/
    if (imgTest.test(url)) {
        fileInfo.fileType = 'image'
    } else if (videoTest.test(url)) {
        fileInfo.fileType = 'video'
    }
    callback(fileInfo)
}*/
/**
 * @desc 响铃
 */
function beep(time) {
    if (navigator.notification === undefined) {
        return
    } else {
        navigator.notification.beep(time);
    }
}
/**
 * @desc 振动
 */
function vibrate(time) {
    navigator.vibrate(time);
}


/**
 * @desc 删除数组中的某个字段
 * @param {*} array 数组
 * @param {*} val 某个字段
 */
function removeArrItem(array, val) {
    Array.prototype.indexOf = function(val) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] == val) return i;
        }
        return -1;
    };
    Array.prototype.remove = function(val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    array.remove(val);
    return array
}

//  MIME 类型列表
const MIME = {
        csv: "text/csv",
        doc: "application/msword",
        docx: "pplication/vnd.openxmlformats-officedocument.wordprocessingml.document",
        pdf: "application/pdf",
        ppt: "application/vnd.ms-powerpoint",
        pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        rar: "application/x-rar-compressed",
        rtf: "application/rtf",
        xhtml: "application/xhtml+xml",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        txt: "text/plain",
        zip: "application/zip",
    }
    /**
     * @desc 下载文件
     * @param {*} url 下载地址
     * @param {*} filename 文件名称
     * @param {*} ext 文件类型
     */
function downloadFile(url, filename, ext) {
    store.commit("loading_status", true);

    axios
        .get(url, {
            responseType: "blob",
        })
        .then((res) => {
            const blob = new Blob([res.data], { type: MIME[ext] }); // 构造一个blob对象来处理数据，并设置文件类型
            store.commit("loading_status", false);

            if (window.navigator.msSaveOrOpenBlob) {
                // 兼容IE10
                navigator.msSaveBlob(blob, filename);
            } else {
                const href = URL.createObjectURL(blob); // 创建新的URL表示指定的blob对象
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = href; // 指定下载链接
                a.download = filename; // 指定下载文件名
                a.click();
                URL.revokeObjectURL(a.href); // 释放URL对象
            }
        });
}
export default {
    mergeJsonObject,
    paramHandle,
    clearStorage,
    removeStore,
    getStore,
    setStore,
    getUserToken,
    getAccessToken,
    randomWord,
    convertBase64UrlToBlob,
    getBase64Image,
    dataURItoBlob,
    beep,
    vibrate,
    removeArrItem,
    downloadFile
};