<template>
  <div class="register">
    <form class="mui-input-group">
      <div class="mui-input-row">
        <label>姓名</label>
        <input type="text" v-model="name" placeholder="请输入姓名" />
      </div>
      <div class="mui-input-row">
        <label>手机</label>
        <input type="number" v-model="mobile" class placeholder="请输入手机号" />
      </div>

      <div class="mui-input-row">
        <label>验证码</label>
        <input type="number" class="code-input" v-model="captcha" placeholder="请输入验证码" />
        <button type="button" :style="`backgroundColor:${$themeColor}`" class="btn" :disabled="sendCodeBtnDisable" @click="sendPhoneCode">
          {{ code_tip }}
        </button>
      </div>
      <div class="mui-input-row">
        <label>密码</label>
        <input type="password" v-model="password" placeholder="请输入密码" />
      </div>
      <div class="mui-input-row">
        <label>确认密码</label>
        <input type="password" v-model="repassword" placeholder="请再次输入密码" />
      </div>
      <div class="mui-input-row mui-radio mui-left">
        <label class="agreeRadio" @click.stop="clickMe">
          <input class="agreeRadio-input" disabled type="checkbox" id="label" v-model="ckeckVal" />
        </label>
        <span class="agreeRadio-span">已同意相关的协议</span>
        <span class="mainTextColor agreeRadio-span" @click="termHandle()">《服务和隐私协议》</span>
      </div>
    </form>

    <div class="mui-button-row">
      <button type="button" :style="`backgroundColor:${$themeColor}`" class="mui-btn updatePwd_btn" @click="submitHandle">
        确认
      </button>
    </div>
  </div>
</template>

<script>
import { publicsReg, getCaptcha } from "@/api/index.js";
import util from "@/utils";
export default {
  name: "alarmMessageDetail",
  data () {
    return {
      code_time: 60,
      code_tip: "发送验证码",
      sendCodeBtnDisable: false,
      mobile: "",
      captcha: "",
      password: "",
      repassword: "",
      name: "",
      ckeckVal: false,
    };
  },
  created () {
    var that = this;
    this.init_query();
  },
  methods: {
    clickMe () {
      const mui = this.mui;
      if (!this.ckeckVal) {
        mui.toast("请先阅读《服务和隐私协议》", {
          duration: "long",
          type: "div",
        });
      }
    },
    init_query () {
      this.ckeckVal = this.$store.state.ckeckVal;
      console.log(this.ckeckVal, "ckeckVal");
      //读取本地session赋值前三条数据
      const queryParam = JSON.parse(sessionStorage.getItem("queryParam"));
      if (queryParam) {
        this.name = queryParam.name;
        this.mobile = queryParam.mobile;
        this.password = queryParam.password;
        this.repassword = queryParam.repassword;
        this.captcha = queryParam.captcha;
      }
    },
    go (url) {
      this.$router.push({
        path: url,
      });
    },
    /**
     * 查看协议
     */
    termHandle () {
      const that = this;
      let query = {
        name: that.name,
        mobile: that.mobile,
        password: that.password,
        repassword: that.repassword,
        captcha: that.captcha,
      };
      //将前三条信息存入本地缓存sessionStorage
      sessionStorage.setItem("queryParam", JSON.stringify(query));
      that.go("/agreement");
    },
    /**
     * 提交注册
     */
    async submitHandle () {
      try {
        const that = this;
        const mui = this.mui;
        if (that.mobile === "") {
          mui.toast("手机号不能为空");
          return;
        }
        if (that.name === "") {
          mui.toast("姓名不能为空");
          return;
        }
        var reg_name = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
        if (!reg_name.test(that.name)) {
          mui.toast("姓名只能输入中文，最小2字符，最大20字符", {
            duration: "long",
            type: "div",
          });
          return;
        }

        if (that.captcha === "") {
          mui.toast("验证码不能为空");
          return;
        }
        if (that.password === "" || this.repassword == "") {
          mui.toast("密码不能为空");
          return;
        }

        var reg_pwd = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/;
        if (!reg_pwd.test(this.password)) {
          mui.toast("密码至少八位，需要包含大小写字母、数字、符号至少三种组合");
          return;
        }

        if (this.password != this.repassword) {
          mui.toast("确认密码不一致，请重新核对");
          return;
        }

        if (!that.ckeckVal) {
          mui.toast("请同意使用条款");
          return;
        }

        var params = {
          mobile: that.mobile,
          password: that.password,
          repassword: that.repassword,
          captcha: that.captcha,
          name: that.name,
        };
        let res = await publicsReg(params).then(that.loginHandle);
        if (res.success) {
          mui.toast(res.message);
          setTimeout(() => {
            let query = {
              token: res.result.token,
              type: "register",
            };

            that.$router.push({
              path: "/userInfo",
              query,
            });
            // that.logout();
            sessionStorage.removeItem("queryParam");
            that.$store.commit("ckeckVal", "");
          }, 1500);
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 原生交互
     * @param tip
     * ios：https://www.jianshu.com/p/426f62f8d1ab
     */
    logout () {
      const mui = this.mui;
      try {
        appClient.exitPage();
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    /*
     * 发送验证码
     * */
    sendPhoneCode: function () {
      const that = this;
      /**
       * 获取组件中表单中的手机号码
       */
      // 注册绑定模式: "0", 登录验证:"1", 修改密码模式: "2"s
      const params = {
        smsMode: "0",
        mobile: that.mobile,
      };
      getCaptcha(params).then(that.codeHandle);
    },
    codeHandle (res) {
      const that = this;
      const mui = this.mui;
      const result = res;
      if (result.success) {
        that.countDown();
        mui.toast(result.message);
        that.sendCodeBtnDisable = true;
      } else {
        that.sendCodeBtnDisable = false; //错误的情况下 解除禁止
        mui.toast(result.message);
      }
    },
    /*
     * 倒计时
     *
     */
    countDown () {
      const that = this;
      const timer = setInterval(() => {
        that.code_tip = this.code_time + "s";
        that.code_time--;
        if (that.code_time <= 0) {
          clearInterval(timer);
          that.sendCodeBtnDisable = false;
          that.code_tip = "获取验证码";
          that.code_time = 60;
        }
      }, 1000);
    },
  },
};
</script>

 <style scoped lang="scss">
.register {
  margin-top: 50px;
}

.register .page {
  height: 80%;
}

.register .text-margin {
  margin: 0 10px;
  font-size: 12px;
}

.register .agreeRadio-span {
  font-size: 12px;
}
.code-input {
  width: 35% !important;
  float: left !important;
}

.agreeRadio {
  width: 10% !important;
}
.agreeRadio-input {
  position: relative;
  top: 4px;
  right: 10px;
  width: 30px;
  height: 15px;
  text-align: center;
}
 .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
.btn{
  color: #fff;
}
</style>
