import axios from 'axios';
import util from '@/utils';
import store from '@/store'

let host = process.env.VUE_APP_BASE_API;
console.log("process:", process);
console.log("host:", host);
// 创建axios的一个实例
var instance = axios.create({
    baseURL: host
});
// 一、请求拦截器 忽略
instance.interceptors.request.use(function(config) {
    //加载中 TODO:
    // 切记位置不要发生变化
    console.log('request-config: ', config);
    config.headers = {
        'X-Access-Token': util.getStore('user_token'),
        // 'X-Access-Token':"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTE5MTk0NTQsIm1lbWJlcklkIjoiMzAifQ.j_EiyXXeezMAVNhLpo6rlJ_6MMrLCv6Bv5nEFqJLy4s",
        "userType":"1",
        'Content-Type': 'application/json;charset=UTF-8'
    };

    // 动画开始
    store.commit("loading_status", true);

    return config;
}, function(error) {
    // 对请求错误做些什么

    // 自定义加载动画结束
    store.commit("loading_status", false);

    console.log('request-error: ', error);
    return Promise.reject(error);
});
// 二、响应拦截器 忽略
instance.interceptors.response.use(function(response) {
    console.log('response: ', response);
    setTimeout(function() {
        // loadingInstance.close();
    }, 1000);

    // 自定义加载动画结束
    store.commit("loading_status", false);

    if (response.code === 1010091) {
        console.log('Origin Request');
        return;
    } else if (response.data.code === 1000002) {
        // router.push({
        //   name:'login'
        // });
        return;
    }
    return response.data;
}, function(error) {
    // 对响应错误做点什么  404 500
    console.log('res-error:', error)

    // 自定义加载动画结束
    store.commit("loading_status", false);

    return Promise.reject(error);
});
export function req(method, url, data) {
    method = method.toLowerCase();
    /* if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
         url = util.url(url);
     }*/
    if (method === 'post') {
        return instance.post(url, data);
    } else if (method === 'get') {
        return instance.get(url, {
            params: data
        });
    } else if (method === 'delete') {
        return instance.delete(url, {
            params: data
        });
    } else if (method === 'put') {
        return instance.put(url, data);
    } else {
        return false;
    }
}