<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <loading v-show="this.$store.state.loading_status"></loading>
  </div>
</template>
<script>
  import loading from "@/components/loading";
  export default {
    components: {
      loading
    },
    provide () {
      return {
        reload: this.reload
      };
    },
    data () {
      return {
        isRouterAlive: true
      };
    },
    methods: {
      reload () {
        this.isRouterAlive = false;
        this.$nextTick(()=> {
          this.isRouterAlive = true;
        });
      }
    },
    created () {
    },
    mounted () {
    }
  };
</script>

<style>
  #app {
    width: 100%  !important;
    /* background-color: #FFFFFF !important; */
  }

  .font-size {
    font-size: 14px  !important;
  }

  /**
     * 浮动
     */
  .float-right {
    float: right !important;
  }

  .content {
    display: flex  !important;
    justify-content: center  !important;
    align-content: center  !important;
  }
  .mui-btn-blue, .mui-btn-primary, input[type=submit] {
    color: #fff;
    border: 1px solid #007aff;
    background-color: #fc701a !important;
  }
  .mui-btn-primary {
    background-color: #fc701a !important;
  }
  .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}

  .btn {
    float: right !important;
    text-align: center !important;
    margin-top: 8px !important;
    margin-right: 10px !important;
    height: 25px !important;
    padding-top: 4px !important;
    font-size: 12px !important;
    border-radius: 20px !important;
    background-color: #fc701a;
    border: none !important;
  }
  .smsButton {
    float: right !important;
    text-align: center !important;
    margin-top: 8px !important;
    margin-right: 10px !important;
    height: 25px !important;
    padding-top: 4px !important;
    font-size: 12px !important;
    border-radius: 20px !important;
    /* background-color: #fc701a; */
    border: none !important;
  }
  .btn:hover {
    /* background-color: #fc701a  !important; */
  }
  .mui-btn-blue.mui-active:enabled,
  .mui-btn-blue:enabled:active,
  .mui-btn-primary.mui-active:enabled,
  .mui-btn-primary:enabled:active,
  input[type="submit"].mui-active:enabled,
  input[type="submit"]:enabled:active {
    color: #fff  !important;
    border: 1px solid #fc701a  !important;
    /* background-color: #fc701a  !important; */
  }
  .mui-input-group .mui-input-row:after {
    height: 1px !important;
  }
</style>
