<template>
  <div class="page">
    <!-- <div class="hander" v-if="list.length == 0">
      [订单编号]{{ info.LogisticCode || "" }}
    </div> -->
    <div class="content bgf" v-if="list.length !=0">
      <div>
        <div class="order">[订单编号]{{ info.LogisticCode || "" }}</div>
        <div class="flex list" v-for="(item, index) in list" :key="index">
          <div class="time">
            <div class="day">{{ item.AcceptTime }}</div>
          </div>
          <div class="info flex1">
            <div class="title">
              {{item.State == 4? "问题件": item.State == 3? "已签收": "配送中"}}
            </div>
            <div class="text">{{ item.AcceptStation }}</div>
          </div>
        </div>
      </div>
    </div> 
    <div class="empty" v-if="list.length == 0">
      <img src="../../assets/img/empty.png" alt />
      <div class="empty_text">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { kdniao } from "@/api/index.js";
export default {
  data() {
    return {
      //物流信息
      list: [],
      info: {},
      logisticsCompanyNo:"",
      logisticsNo:"",
    };
  },
  created() {
    this.logisticsCompanyNo = this.$route.query.logisticsCompanyNo;
    this.logisticsNo = this.$route.query.logisticsNo;
    this.kdniaoInfo();
  },
  methods: {
    kdniaoInfo() {
      let params = {
        logisticsCompanyNo:this.logisticsCompanyNo,
        logisticsNo: this.logisticsNo,
      };
      kdniao(params).then((res) => {
        this.list = res.result.Traces;
        this.info = res.result;
        this.list.sort((a, b) => {
          return a.AcceptTime < b.AcceptTime ? 1 : -1;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%h;
  height: calc(100% - 44px);
  font-size: 15px;
  width: 100%;
  background-color: #eeeeee;
}
.empty {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 30%;
  font-size: 12px;
  background-color: #eeeeee;
  text-align: center;
}
.empty img {
  width: 100%;
  height: 100%;
}
.empty_text {
  position: relative;
  top: -25px;
  background-color: #eeeeee;
}
.hander {
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 15px;
}
.order {
  padding: 20px;
  font-size: 15px;
}
/*flex 转换成flex容器*/
.flex {
  display: flex;
  flex-direction: row;
}
.flex1 {
  flex: 1;
}
.bgf {
  background-color: #fff;
}
//收货地址
.content {
 padding: 20px 24px 56px 5px;
  .list {
    &:first-child {
      .info::before {
        bottom: -20px;
        border-left: 1px dashed #e5e5e5;
      }
      .title {
        &::before {
          width: 20px;
          height: 20px;
          left: -10px;
          background-color: #47a0e9;
        }
      }
    }
    &:last-child {
      .info::before {
        height: 32px;
      }
    }
    &.one {
      .info::before {
        margin-top: 20px;
      }
      .title {
        &::before {
          width: 40px;
          height: 40px;
          left: -23px;
          background-color: #63b8ff;
        }
      }
      .text {
        color: #666;
      }
      .time {
        color: #333;
        .day {
          font-size: 15px;
        }
      }
    }
  }
  .time {
    width: 100px;
    padding-right: 15px;
    font-size: 15px;
    text-align: right;
    color: #999;
    margin-top: 15px;
    padding-left: 5px;
    .day {
      margin-bottom: 4px;
    }
  }
  .info {
    position: relative;
    padding-top: 12px;
    color: #999;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 0;
      border-left: 1px solid #e5e5e5;
    }
    .title {
      position: relative;
      margin-bottom: 10px;
      padding-left: 32px;
      font-size: 15px;
      &::before {
        content: "";
        position: absolute;
        left: -3px;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 7px;
        height: 7px;
        margin: auto 0;
        border-radius: 50%;
        background-color: #cecece;
      }
      &.address {
        font-size: 15px;
      }
    }
    .text {
      padding: 0 0 44px 32px;
    }
  }
}
</style>
