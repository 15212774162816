<template>
  <div class="mui-scroll-wrapper diseaseCode">
    <div class="mui-scroll">
      <!-- <div style="margin: 60px 0px">
        <div
          class="mui-input-row mui-search"
          style="width: 70%; margin: 5px 20px; padding: 0; position: absolute"
        >
          <input
            type="text"
            class="search_input"
            v-model="title"
            placeholder="请输入关键字"
          />
        </div>
        <button class="mui-icon mui-pull-right search_btn" @tap="search">
          搜索
        </button>
      </div> -->
      <div v-if="has_data" class="mui-content">
        <div class="mui-card">
          <ul class="mui-table-view">
            <li
              class="mui-table-view-cell"
              @tap="diseaseInfo(item)"
              v-for="(item, index) in info"
              :key="index"
            >
              <a class="mui-navigate-right">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="mui-content contentTab no-data" v-if="!has_data">
        <h5 class="mui-text-center">暂无信息</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { nationList } from "@/api/index.js";
export default {
  components: {},
  name: "nationList",
  data() {
    return {
      info: [],
      has_data: true,
      more: false,
      token: "",
      type: "",
      title: "",
      pageSize: 15,
      pageNo: 1,
      total: "",
    };
  },
  mounted() {
    var mui = this.mui;
    var that = this;
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
    // 下拉刷新、上拉加载
    mui.init({
      pullRefresh: {
        container: ".mui-scroll-wrapper",
        down: {
          height: 50, // 可选,默认50.触发下拉刷新拖动距离,
          // auto: true, // 可选,默认false.首次加载自动下拉刷新一次
          contentdown: "下拉可以刷新", // 可选，在下拉可刷新状态时，下拉刷新控件上显示的标题内容
          contentover: "释放立即刷新", // 可选，在释放可刷新状态时，下拉刷新控件上显示的标题内容
          contentrefresh: "正在刷新...", // 可选，正在刷新状态时，下拉刷新控件上显示的标题内容
          callback: function () {
            setTimeout(function () {
              console.log("下拉刷新······");
              that.more = false;

              mui(".mui-scroll-wrapper").pullRefresh().endPulldownToRefresh();
            }, 1000);
          },
        }
      },
    });
  },
  created() {
    this.readyInfo();
  },
  methods: {
    search() {
      this.more = false;
      this.readyInfo();
    },
    diseaseInfo(item) {
      var mui = this.mui;
      try {
        appClient.selectNation(item.title);
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    async readyInfo() {
      try {
        var that = this;
        let res = await nationList();
        if (res.success) {
           that.info = res.result;
        } else {
          console.log("请求失败");
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 上拉加载
     */
    load_more() {
      var that = this;
      if (that.pageNo * that.pageSize <= this.total) {
        that.pageNo++;
        that.more = false;
      } else {
        that.more = true;
      }
      that.readyInfo("more");
    },
  },
};
</script>

 <style scoped lang="scss">
.diseaseCode_tenter {
  margin-top: 60px;
}
.mui-btn-outlined {
  width: 60px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  position: absolute;
  right: 15px;
  padding: 0px;
}

.search_btn {
  border: none;
  top: 9px;
  right: 10px;
  font-size: 16px !important;
  background-color: none;
  z-index: 100;
}
.search_input {
  height: 35px !important;
  line-height: 40px !important;
  background-color: #e5e5e5 !important;
  border-radius: 15px !important;
  border: none !important;
  z-index: 100;
}
.mui-input-row.mui-search .mui-icon-clear {
  /* top: 10px; */
  display: none;
}
.search_beader {
  height: 50px;
}
.no-data {
  margin-top: 50px !important;
}
.mui-navigate-right {
  white-space: normal !important;
  padding-right: 25px !important;
}
.mui-control-item {
  touch-action: none;
}
</style>
