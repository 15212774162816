<template>
  <div class="im-message-body">
    <h1>医患聊天信息记录表</h1>
    <table>
      <tr>
        <th>发送方</th>
        <th>聊天内容</th>
        <th>消息类型</th>
      </tr>
      <tr v-for="item in orderList" :key="item.id">
        <td style="font-size: 13px">
          {{ item.sender }}[{{ item.senderType }}]
        </td>
        <td style="word-wrap: break-word; word-break: normal; font-size: 11px">
          <p class="" v-if="item.msg_type === 'PICTURE'">
            <a
              target="_blank"
              class="mainTextColor"
              @click="onItem(item.body, item.msg_type)"
              >查看图片</a
            >
            <span style="margin-left: 40px">发送时间：{{ item.sendTime }}</span>
          </p>
          <p class="" v-if="item.msg_type === 'FILE'">
            <a class="mainTextColor" @click="onDownloadFile(item)"
              >查看文件</a
            >
            <span style="margin-left: 40px">发送时间：{{ item.sendTime }}</span>
          </p>
          <p class="" v-if="item.msg_type === 'VIDEO'">
            <a
              target="_blank"
              class="mainTextColor"
              @click="onItem(item.body, item.msg_type)"
            >
              查看视频</a
            >
            <span style="margin-left: 40px">发送时间：{{ item.sendTime }}</span>
          </p>
          <p class="" v-if="item.msg_type === 'AUDIO'">
            <a
              target="_blank"
              class="mainTextColor"
              @click="onItem(item.body, item.msg_type)"
              >查看音频</a
            >
            <span style="margin-left: 40px">发送时间：{{ item.sendTime }}</span>
          </p>
          <p class="" v-if="item.msg_type === 'TEXT'">
            {{ item.body }}
            <span style="margin-left: 40px">发送时间：{{ item.sendTime }}</span>
          </p>
        </td>
        <td style="word-wrap: break-word; word-break: normal; font-size: 11px">
          <span v-if="item.msg_type === 'TEXT'">文本消息</span>
          <span v-if="item.msg_type === 'PICTURE'">图片消息</span>
          <span v-if="item.msg_type === 'FILE'">文件消息</span>
          <span v-if="item.msg_type === 'VIDEO'">视频消息</span>
          <span v-if="item.msg_type === 'AUDIO'">音频消息</span>
        </td>
      </tr>
    </table>

    <div
      class="popup"
      v-if="isShowImg"
      @touchmove.stop.prevent
      @mousewheel.prevent
      @click.stop="isShowImg = false"
    >
      <img :src="imgUrl" v-if="imgUrl" />
      <video controls :src="videoUrl" v-if="videoUrl"></video>
      <audio controls :src="audioUrl" v-if="audioUrl"></audio>
    </div>
  </div>
</template>
<script>
import { orderTreatProcess } from "@/api/index.js";
import util from "@/utils";


export default {
  name: "orderTreatProcess",
  data() {
    return {
      orderList: {},
      content: "",
      orderTreatId: "",
      imgUrl: "",
      videoUrl: "",
      audioUrl: "",
      isShowImg: false,
    };
  },
  created() {
    var mui = this.mui;
    this.orderTreatId = this.$route.query.orderTreatId;
    console.log(this.orderTreatId);
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
  },
  methods: {
    /**
     * 初次获取数据
     */
    async readyInfo() {
      try {
        const mui = this.mui;
        var params = {
          orderTreatId: this.orderTreatId,
        };
        let res = await orderTreatProcess(params);
        if (res.success) {
          this.orderList = res.result;
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查看
    onItem(url, type) {
      this.imgUrl = "";
      this.videoUrl = "";
      this.audioUrl = "";
      this.isShowImg = true;

      if (type == "PICTURE") return (this.imgUrl = url);
      if (type == "VIDEO") return (this.videoUrl = url);
      if (type == "AUDIO") return (this.audioUrl = url);
    
    },
    //查看文件
    onDownloadFile(item){
util.downloadFile(item.body,item.fileName,item.suffix)
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.im-message-body {
  width: 100vw;
  height: 100%;
  position: relative;
  background-color: #fff;

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100vw;
      max-height: 100vh;
    }

    video {
      width: 500px;
      height: 500px;
      background-color: #000;
    }

    audio {
      margin: auto 0;
    }
  }

  h1 {
    text-align: center;
    padding: 20px 0;
  }

  table {
    margin: 0 auto;
    margin-bottom: 10%;
    text-align: center;
  }

  tr th:nth-child(1) {
    width: 20%;
  }

  tr th:nth-child(2) {
    width: 60%;
  }
  p {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 10%);
    margin: 0 auto;
  }
  img {
    margin: 20px;
  }
  span {
    width: 50%;
    text-align: center;
  }
}
</style>