<template>
  <div class="orderBookItemDetail">
    <ul class="d_Ul"  v-if="info.caseContent_dictText">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 订单信息</p>

      <li>预约项目：{{info.title}}</li>
      <li>预约人：{{info.memberName}}</li>
      <li class="">预约类型： {{info.itemType_dictText}}</li>
      <li class="">所属科室： {{info.department}}</li>
      <li v-if="info.logisticsNumber">物流编号：{{info.logisticsNumber}}</li>
      <li>预约日期：{{info.orderDate}}</li>
      <li>创建日期：{{info.createTime}}</li>

    </ul>
    <ul class="d_Ul" v-if="caseInfo">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 患者信息</p>
      <li>出生日期：{{caseInfo.birthdate}}</li>
      <li>所属患者：{{caseInfo.name}}</li>
      <li>年龄：{{caseInfo.age}}岁</li>
      <li >性别：
       <span v-if="caseInfo.gender === 2">女</span>
        <span v-else-if="caseInfo.gender === 1">男</span>
        <span v-else >未知</span>
      </li>
      <li>手机号：{{caseInfo.mobile}}</li>
      <li>身份证号：{{caseInfo.idCard}}</li>
    </ul>
    <ul class="d_Ul" v-if="caseInfo">
      <p> <span :style="`backgroundColor:${$themeColor}`"></span> 病情描述</p>
      <li>药物史：{{caseInfo.drugHistory}}</li>
      <li>过敏史：{{caseInfo.allergy}}</li>
      <li>手术史：{{caseInfo.operationHistory}}</li>
      <li>病情描述：{{caseInfo.described}}</li>
      <!-- <li>相关资料：{{info.caseContent_dictText.symptom}}</li> -->
    </ul>
  </div>
</template>

<script>
import { orderBookItemDetail } from "@/api/index.js";
export default {
  name: "detectionDetail",
  data() {
    return {
      info: {},
      caseInfo:{},
      content: "",
      money: 10,
      messageId: "",
      token: "",
      id: "",
    };
  },
  created() {
    var mui = this.mui;
    this.id = this.$route.query.id;
    console.log(this.id)
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });
  },
  methods: {
    /**
     * 初次获取数据
     */
    async readyInfo() {
      
      try {
        const mui = this.mui;
        var params = {
          id: this.id
        };
        
        let res = await orderBookItemDetail(params);
        if (res.success) {
          this.info = res.result;
          this.caseInfo = res.result.caseContent_dictText;
          console.log(this.info, " this.info ");
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style lang="scss">
  .orderBookItemDetail{
    overflow: auto;
    padding: 10px;
    .d_Ul{
      border-radius: 8px;
      background: #fff;
    }
     .d_Ul li{
      font-size: 14px;
      line-height: 25px;
      color: #666;
      margin-bottom: 10px;
    }
     .d_Ul p{
      font-size: 16px;
      color:#333;
    }
    .d_Ul p span{
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 15px;
      border-radius: 15px;
      background: var(--theme);
    }
  }

</style>