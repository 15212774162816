<template>
  <div class="proveMobile">
    <!-- <div class="replaceMobile-title">验证新的手机号码</div> -->
    <form class="mui-input-group">
      <div class="mui-input-row">
        <label class="proveMobile-label">新手机</label>
        <input
          type="text"
          v-model="mobile"
          class="mui-input-clear"
          placeholder="请输入新手机"
        />
      </div>
      <div class="mui-input-row">
        <label>验证码</label>
        <input
          type="text"
          class="code-input"
          v-model="captcha"
          placeholder="请输入验证码"
        />
        <button
          type="button"
           :style="`backgroundColor:${$themeColor}`"
          class="btn"
          :disabled="sendCodeBtnDisable"
          @click="sendPhoneCode"
        >
          {{ code_tip }}
        </button>
      </div>
    </form>
    <div class="mui-button-row">
      <button
        type="button"
         :style="`backgroundColor:${$themeColor}`"
        class="mui-btn updatePwd_btn"
        @click="upSub"
      >
        提交
      </button>
    </div>
  </div>
</template>


<script>
import { getCaptcha, changMobile } from "@/api/index.js";

export default {
  name: "alarmMessageDetail",
  data() {
    return {
      token: "",
      mobile: "",
      captcha: "",
      code_time: 60,
      code_tip: "发送验证码",
      sendCodeBtnDisable: false,
    };
  },
  components: {},
  methods: {
    /*
     * 发送验证码
     * */
    async sendPhoneCode() {
      try {
        const mui = this.mui;
        // 注册绑定模式: "0", 登录验证:"1", 修改密码模式: "2" 修改手机号3
        const params = {
          smsMode: "3",
          mobile: this.mobile,
        };
        let res = await getCaptcha(params);
        if (res.success) {
          this.countDown();
          mui.toast(res.message);
          this.sendCodeBtnDisable = true;
        } else {
          this.sendCodeBtnDisable = false; //错误的情况下 解除禁止
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
     * 倒计时
     *
     */
    countDown() {
      const timer = setInterval(() => {
        this.code_tip = this.code_time + "s";
        this.code_time--;
        if (this.code_time <= 0) {
          clearInterval(timer);
          this.sendCodeBtnDisable = false;
          this.code_tip = "获取验证码";
          this.code_time = 60;
        }
      }, 1000);
    },
    /**
     * 提交
     */
    async upSub() {
      try {
        const mui = this.mui;
        if (this.mobile === "") {
          mui.toast("手机号不能为空");
          return;
        }
        const params = {
          captcha: this.captcha,
          mobile: this.mobile,
        };
        var  mobile  =this.mobile;
        let res = await changMobile(params);
        if (res.success) {
          mui.toast(res.message);
          setTimeout(() => {
            try {
              appClient.proveMobile(mobile); //关闭当前页面
            } catch (e) {
              mui.toast(
                "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
              );
            }
          }, 1500);
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.$util.setStore("user_token", this.token);
    console.log(this.token, " this.token");
  },
};
</script>

 <style lang="scss">
.proveMobile {
  margin-top: 50px;
}

.proveMobile .replaceMobile-title {
  margin: 40px 20px !important;
  font-weight: 700;
}

.proveMobile .code-input {
  width: 35% !important;
  float: left !important;
}
.proveMobile .btn {
  float: right;
  text-align: center;
  margin-top: 8px;
  height: 25px;
  padding-top: 3px;
  font-size: 12px;
  border-radius: 20px;
}
 .updatePwd_btn{
    width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
.btn{
  color: #fff;
}
</style>
