/**
 * @desc 应用vue 混入 处理通用js 方法或者字段
 * 
 */
import util from '../utils'
export default {
  data () {
    return {
    }
  },
  onShow () {
    // if ( this.title !== undefined ) {
    //   uni.setNavigationBarTitle( {
    //     title: `${this.title}`
    //   } );
    // }
    // this.setTabBar()
  },
  computed: {
    i18n () {
      return this.$t( "index" );
    },
  },
  onShareAppMessage ( res ) {

  },
  methods: {
    tipsShow ( msg ) {
      // this.$refs.tips.modalName = "tips";
      // this.$refs.tips.tips = msg;
    },
    tipsHide () {
      // this.$refs.tips.modalName = null;
    },
    // setTabBar() {
    //   let list = [`${this.i18n.home}`, '索赔', '索赔进度', `${this.i18n.my}`];
    //   for (let i = 0; i < list.length; i++) {
    //     uni.setTabBarItem({
    //       index: i,
    //       text: `${list[i]}`
    //     });
    //   }
    // }
  },
}