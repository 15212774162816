import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

// 定义路由

import register from '@/components/register/register';
import forget from '@/components/forget/forget';
import agreement from '@/components/agreement/agreement';
import about from '@/components/about/about';
import userInfo from '@/components/userInfo/userInfo';
import articleDetails from '@/components/articleDetails/articleDetails';
import proveMobile from '@/components/proveMobile/proveMobile';
import changePassword from '@/components/changePassword/changePassword';
import knowing from '@/components/knowing/knowing';
import download from '@/components/download/download';
import pdf from '@/components/pdf/pdf';
import diseaseCode from '@/components/diseaseCode/diseaseCode';
import region from '@/components/region/region';
import deleteAccount from '@/components/deleteAccount/index';
import noFound from '@/components/common/404';
import addExamineOrder from '@/components/addExamineOrder/addExamineOrder';
import addExamineOrderDetail from '@/components/addExamineOrderDetail/addExamineOrderDetail';
import getMedicalList from '@/components/getMedicalList/index';
import hisAllergicList from '@/components/hisAllergicList/index';
import sysDrugList from '@/components/sysDrugList/index';
import logistics from '@/components/logistics/logistics';
import outpatientServiceDetail from '@/views/book/outpatientServiceDetail';
import outpatientServiceList from '@/views/book/outpatientServiceList';
import orderTreatProcess from '@/views/im/orderTreatProcess';
import inspectionReport from '@/views/report/inspectionReport';
import messageList from '@/views/message/messageList';
import messageDetail from '@/views/message/messageDetail';
import electronicMedicalRecords from '@/views/book/electronicMedicalRecords';
import prescriptionDetail from '@/views/prescription/prescriptionContent.vue'

// import prescriptionReport from '@/views/prescriptionReport/reportDetail.vue';
import nation from '@/views/nation/index';


const routes = [
  // 报告详情
  // {
  //   path: '/reportDetail',
  //   name: 'reportDetail',
  //   component: prescriptionReport,
  //   meta: {
  //     requiresAuth: true,
  //     title: '报告详情' // 标题设置
  //   }
  // },
     //物流信息
     {
      path: '/logistics',
      name: 'logistics',
      component: logistics,
      meta: {
        requiresAuth: true,
        title: '物流信息' // 标题设置
      }
    },
   //处方详情
   {
    path: '/prescription',
    name: 'prescription',
    component: prescriptionDetail,
    meta: {
      requiresAuth: true,
      title: '处方详情' // 标题设置
    }
  },
  //注册账号
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      requiresAuth: true,
      title: '注册账号' // 标题设置
    }
  },
  //忘记密码
  {
    path: '/forget',
    name: 'forget',
    component: forget,
    meta: {
      requiresAuth: true,
      title: '忘记密码' // 标题设置
    }
  },
  //注册协议
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement,
    meta: {
      requiresAuth: true,
      title: '用户协议' // 标题设置
    }
  },
  //关于我们
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      requiresAuth: true,
      title: '关于我们' // 标题设置
    }
  },
  //个人详情
  {
    path: '/userInfo',
    name: 'userInfo',
    component: userInfo,
    meta: {
      requiresAuth: true,
      title: '个人资料' // 标题设置
    }
  },
  //健康详情
  {
    path: '/articleDetails',
    name: 'articleDetails',
    component: articleDetails,
    meta: {
      requiresAuth: true,
      title: '收费明细' // 标题设置
    }
  },
  //更换手机
  {
    path: '/proveMobile',
    name: 'proveMobile',
    component: proveMobile,
    meta: {
      requiresAuth: true,
      title: '更换手机' // 标题设置
    }
  },
  //修改密码
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword,
    meta: {
      requiresAuth: true,
      title: '修改密码'
    }
  },
  //修改密码
  {
    path: '/knowing',
    name: 'knowing',
    component: knowing,
    meta: {
      requiresAuth: true,
      title: '知情同意书'
    }
  },
  //下载
  {
    path: '/download',
    name: 'download',
    component: download,
    meta: {
      requiresAuth: true,
      title: '患者app下载'
    }
  },
  //处方笺
  {
    path: '/pdf',
    name: 'pdf',
    component: pdf,
    meta: {
      requiresAuth: true,
      title: '我的报告'
    }
  },
  {
    path: '/diseaseCode',
    name: 'diseaseCode',
    component: diseaseCode,
    meta: {
      requiresAuth: true,
      title: '疾病列表'
    }
  },
  {
    path: '/region',
    name: 'region',
    component: region,
    meta: {
      requiresAuth: true,
      title: '选择地区'
    }
  }, {
    path: '/deleteAccount',
    name: 'deleteAccount',
    component: deleteAccount,
    meta: {
      requiresAuth: true,
      title: '注销账户'
    }
  },
  {
    path: '/404',
    name: 'noFound',
    component: noFound,
    meta: {
      requiresAuth: true,
      title: '访问页面404'
    }
  },
  //核算检测预约
  {
    path: '/addExamineOrder',
    name: 'addExamineOrder',
    component: addExamineOrder,
    meta: {
      requiresAuth: true,
      title: '核酸检测' // 标题设置
    }
  },
  //核算检测预约详情
  {
    path: '/addExamineOrderDetail',
    name: 'addExamineOrderDetail',
    component: addExamineOrderDetail,
    meta: {
      requiresAuth: true,
      title: '核酸检测详情' // 标题设置
    }
  },
  //获取病史等列表
  {
    path: '/getMedicalList',
    name: 'getMedicalList',
    component: getMedicalList,
    meta: {
      requiresAuth: true,
      title: '病史列表' // 标题设置
    }
  },
  //获取过敏史等列表
  {
    path: '/hisAllergicList',
    name: 'hisAllergicList',
    component: hisAllergicList,
    meta: {
      requiresAuth: true,
      title: '过敏史列表' // 标题设置
    }
  },
  //获取用药史等列表
  {
    path: '/sysDrugList',
    name: 'sysDrugList',
    component: sysDrugList,
    meta: {
      requiresAuth: true,
      title: '用药史列表' // 标题设置
    }
  },
  {
    path: '/outpatientServiceDetail',
    name: 'outpatientServiceDetail',
    component: outpatientServiceDetail,
    meta: {
      requiresAuth: true,
      title: '门诊预约详情' // 标题设置
    }
  },
  {
    path: '/outpatientServiceList',
    name: 'outpatientServiceList',
    component: outpatientServiceList,
    meta: {
      requiresAuth: true,
      title: '门诊预约订单' // 标题设置
    }
  },
  {
    path: '/orderTreatProcess',
    name: 'orderTreatProcess',
    component: orderTreatProcess,
    meta: {
      requiresAuth: true,
      title: '聊天记录' // 标题设置
    }
  },
  {
    path: '/inspectionReport',
    name: 'inspectionReport',
    component: inspectionReport,
    meta: {
      requiresAuth: true,
      title: '化验报告详情' // 标题设置
    }
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: messageList,
    meta: {
      requiresAuth: true,
      title: '消息列表' // 标题设置
    }
  },
  {
    path: '/messageDetail',
    name: 'messageDetail',
    component: messageDetail,
    meta: {
      requiresAuth: true,
      title: '消息详情' // 标题设置
    }
  },
  {
    path: '/electronicMedicalRecords',
    name: 'electronicMedicalRecords',
    component: electronicMedicalRecords,
    meta: {
      requiresAuth: true,
      title: '电子病历详情' // 标题设置
    }
  },
  {
    path: '/nation',
    name: 'nation',
    component: nation,
    meta: {
      requiresAuth: true,
      title: '民族' // 标题设置
    }
  }
];
let router = new VueRouter({
  routes
});

export default router
