<template>
  <div class="mui-content healthDetails mui-scroll-wrapper" style="height: 100%; overflow: scroll">
    <div class="mui-card">
      <div class="mui-card-header header-font">
        <span>{{ info.title }}</span>
      </div>

      <!-- <div class="float-right" @click="addArticle">
        <img v-show="info.collectStatus == 1" class="content-img" src="../../assets/img/collect_select.png" alt />
        <img v-show="info.collectStatus == 0" class="content-img" src="../../assets/img/collect.png" alt />
      </div> -->

      <div class="mui-card-content">
        <div class="mui-card-content-inner content-inner-img">
          <span class="header-font-right">{{ info.createTime }}</span>

        </div>
        <div v-html="content" class="mui-card-content-inner content-inner-font"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { getAdviseDetail, addCollection } from "@/api/index.js";

export default {
  name: "alarmMessageDetail",
  data() {
    return {
      message_id: "",
      info: {},
      content: "",
      money: 10,
      messageId: "",
      token: "",
      id: "",
    };
  },
  components: {},
  created() {
    var mui = this.mui;
    this.id = this.$route.query.id;
    this.readyInfo();
    mui(".mui-scroll-wrapper").scroll({
      deceleration: 0.0005, //flick 减速系数，系数越大，滚动速度越慢，滚动距离越小，默认值0.0006
    });

  },
  methods: {
    /**
     * 收藏
     *
     */
    async addArticle() {
      try {
        const mui = this.mui;
        const params = {
          articleId: this.id,
        };
        if (this.info.collectStatus == 1) {
          this.info.collectStatus = 0
        } else {
          this.info.collectStatus = 1
        }
        let res = await addCollection(params);
        console.log("this.id:", this.id);
        console.log("this.info.collectStatus:", this.info.collectStatus);
        try {
          var collectStatus = 0;
          if (this.info.collectStatus === 0) {
            collectStatus = 1
          }
          appClient.updateArticleLikeStatus(this.id, collectStatus);
        } catch (e) {
          mui.toast(
            "出现错误, 如果在非android环境下访问, 出现该警告是正常的."
          );
        }
        // setTimeout(() => {
        //   this.readyInfo();
        // }, 3000);

      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 初次获取数据
     */
    async readyInfo() {
      try {
        const mui = this.mui;
        var params = {
          id: this.id,
          token: this.token,
        };
        let res = await getAdviseDetail(params);
        if (res.success) {
          this.info = res.result;
          this.content = res.result.content.replace(
            new RegExp("<img", "g"),
            "<img style='height:auto;max-width:100%;'"
          );
          console.log(this.info, " this.info ");
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
 <style scoped lang="scss">
.healthDetails {
  .header-font {
    font-size: 20px;
  }
  .mui-card-content-inner {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
  .content-inner-font {
    text-indent: 1em;
    font-size: 18px;
  }
  .content-inner-img {
    padding: 0;
    height: 50px;
  }
  .content-img {
    width: 25px;
    height: 25px;
    margin: 10px 10px 10px 0;
  }
  .header-font-right {
    float: left;
    height: 40px;
    font-size: 14px;
    margin-left: 15px;
    margin-top: 12px;
    color: #ccc;
  }
  .float-right {
    position: fixed;
    right: 30px;
    top: 500px;
    width: 60px;
    height: 60px;
    z-index: 9999;
    background-color: transparent;
  }
}
</style>
