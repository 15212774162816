<template>
  <div class="about">
    <div class="mui-card-content">
      <!--      <div class="content font-title">关于我们</div>-->
      <img class="content-img" v-if="info.logo" :src="info.logo" alt />
      <img class="content-img" v-else :src="logo" alt />
      <div class="content-font" v-text="info.info"></div>
    </div>
    <div class="card-footer">
      <div class="font-title">联系我们</div>
      <div>电话：{{ info.mobile }}</div>
      <div>email：{{ info.email }}</div>
      <!--      <div>WeChat：{{info.wechat}}</div>-->
      <div>地址：{{ info.address }}</div>
    </div>
  </div>
</template>

<script>
import { getAbout } from "@/api/index.js";
export default {
  name: "about",
  data() {
    return {
      info: {},
      logo:require(process.env.VUE_APP_LOGO_PATH)
    };
  },
  components: {},
  created() {
    this.readyInfo();
  },
  methods: {
    async readyInfo() {
      try {
        var params = {};
        let res = await getAbout(params);
        if (res.success) this.info = res.result;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

 <style lang="scss">
.about {
  margin: 50px;
}
.content-img {
  width: 60%;
  display: block;
  margin: auto;
}

.about .mui-card {
  margin: 30px;
}
.content-font {
  margin: 20px 0;
  text-align: center;
  font-size: 14px;
}
.card-footer {
  margin: 60px 0;
  text-align: center;
  font-size: 14px;
}

.about .font-title {
  margin: 20px 0;
  color: #0062cc;
  font-weight: 700;
  font-size: 16px;
}
</style>
