<template>
  <div class="pdf">
    <!--    这个是ok 但是不支持跨域的-->
    <pdf
      height="100%"
      :src="pdfUrl"
      @num-pages="pageCount=$event"
      @page-loaded="currentPage=$event"
      @loaded="loadPdfHandler"
    ></pdf>
    <!-- <div class="float-right" @click="floatClick">
      <span class="float-icon">下载</span>
    </div> -->
  </div>
</template>

<script>
  import pdf from "vue-pdf";
  import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";

  export default {
    metaInfo: {
      meta: [
        {
          name: "viewport",
          content: "width=device-width,initial-scale=1,user-scalable=0"
        }
      ]
    },
    components: { pdf },
    data() {
      return {
        info: {},
        pdfUrl: "",
        // pdfUrl: "http://dev-platform.ifarmiot.com//hospital_upload/pdf/2021/4/1/6e28dd90ddce26409ccbdb1a1fe378de.pdf",
        url: "",

        currentPage: 1, // pdf文件页码
        pageCount: 1, // pdf文件总页数
        report_id:'',
        fileType: "pdf", // 文件类型

        numPages: undefined
      };
    },
    created() {
      var that = this;
      console.log("that.$route.query:",that.$route.query);
      // that.report_id = that.$route.query.report_id;
      var pdfUrl = this.pdfUrl;
      if (that.$route.query.pdfUrl){
        pdfUrl = that.$route.query.pdfUrl;
        this.url = that.$route.query.pdfUrl;
      }
      this.pdfUrl = pdf.createLoadingTask({ url: pdfUrl, CMapReaderFactory });

      // var pdfUrl = "http://image.cache.timepack.cn/nodejs.pdf";

      // // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
      // this.pdfUrl = pdf.createLoadingTask(this.pdfUrl);
      // this.pdfUrl.then(pdf => {
      //   this.numPages = pdf.numPages;
      // });
      this.isWechat();
    },
    methods: {
      // pdf加载时
      loadPdfHandler(e) {
        this.currentPage = 1; // 加载的时候先加载第一页
      },
      /**
       * 判断是否是浏览器
       */
      isWechat() {
        var We = window.navigator.userAgent.toLowerCase();
        console.log(We, "We");
        if (We.match(/MicroMessenger/i) == "micromessenger") {
          this.is_wechat = true;
        } else {
          this.is_wechat = false;
        }
      },
      /**
       * 下载
       */
      floatClick() {
        var mui = this.mui;
        // var that = this;
        console.log("this.url",this.url);
        try {
          appClient.downloadFile(this.url);
        } catch (e) {
          mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
        }
      },

        // var userAgent = navigator.userAgent.toLowerCase(); //获取UA信息
            //  console.log("userAgent：",userAgent);
        // if (userAgent.indexOf("onlinemedicine") != -1) {
          //判断ua中是否含有和app端约定好的标识onlinemedicine
          // console.log("isAPP中包含onlinemedicine");
          // try {
          //   appClient.download(that.url);
          // } catch (e) {
          //   mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
          // }
        // }

        //判断是否是在浏览器
        // if (that.is_wechat) {
        //   mui.toast("请点击右上角并用浏览器打开");
        // } else {
        //   console.log("执行web下载", that.downloadPdf);
        //   window.open(that.downloadPdf, "_blank");
        // }
      /**
       * 获取协议内容
       */
      readyInfo() {
        var that = this;
        var params = {
          id: that.report_id
        };
        preview(params).then(res => {
          console.log(res, "res");
          that.info = res.data;
        });
      }
    }
  };
</script>

 <style lang="scss">
  .pdf {
    height: 100%;
    width: 100%;
  }
  .img {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .img img {
    width: 150px;
    height: 150px;
  }
  .pdf_text {
    text-align: center;
    /* color: #B2B2B2; */
  }
  .pdf-box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 0.28rem;
  }
  span {
    width: 100%;
  }

  .float-right {
    position: fixed;
    right: 30px;
    top: 500px;
    width: 60px;
    height: 60px;
    background-color: transparent;
  }
  .float-icon {
    width: 60px;
    height: 60px;
    background-color: #00afff !important;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
  }
</style>
