<template>
  <div class="register">
    <form class="mui-input-group">
      <div class="mui-input-row" v-if="input_show">
        <label>注销原因</label>
        <input type="text" v-model="delCause" class placeholder="请输入注销原因" />
      </div>

      <div class="mui-input-row" v-if="input_show">
        <label>验证手机</label>
        <div class="captcha_phone">
          <p>{{estarPhone(mobilePhone)}}</p>
        </div>
      </div>
      <div class="mui-input-row" v-if="input_show">
        <div class="captcha-item" > 
          <input class="captcha_input" v-model="phoneCaptcha" placeholder="请输入验证码" type="text" />
          <button
          type="button"
           :style="`backgroundColor:${$themeColor}`"
          class="get-captcha"
          :disabled="sendCodeBtnDisable"
          @click="sendPhoneCode"
        >
          {{ captcha_tip }}
        </button>
          <!-- <button class="get-captcha" :style="`backgroundColor:${$themeColor}`" size="mini" @click="getPhoneCaptcha" :disabled="sendCodeBtnDisable"> {{captcha_tip || '获取验证码' }}</button> -->
        </div>
      </div>

      <div class="mui-input-row mui-radio mui-left">
        <label class="agreeRadio" @click.stop="clickMe">
          <input class="agreeRadio-input" disabled type="checkbox" v-model="ckeckVal" />
        </label>
        <span class="agreeRadio-span" :disabled="isDisable">{{ code_tip }}</span>
        <span class="mainTextColor agreeRadio-span" @click="termHandle()">《用户注销协议须知》</span>
      </div>
    </form>

    <div class="mui-button-row" v-if="input_show">
      <button type="button" :style="`backgroundColor:${$themeColor}`" class="mui-btn updatePwd_btn" @click="submitHandle">
        注销
      </button>
    </div>
    <agreement v-if="agreement_show" @agreeHandle="agreeHandle" term_type="patient_delete" />
  </div>
</template>

<script>
import {
  getCaptcha,
  getSickInfo,
  deleteAccount,
} from "@/api/index.js";
import agreement from "@/components/agreement/agreement.vue";
export default {
  name: "alarmMessageDetail",
  components: {
    agreement,
  },
  data () {
    return {
      captcha_time: 60,
      // code_tip: "发送验证码",
      mobile: "",
      captcha: "",
      password: "",
      ckeckVal: false,
      agreement_show: false,
      input_show: true,
      delCause: "",
      id: 9,
      term_type: "",
      isDisable: true,
      code_time: 10,
      code_tip: '已同意相关的协议',
      mobilePhone:'',
      phoneCaptcha:'',
      sendCodeBtnDisable: false,
      captcha_tip: "获取验证码",
    };
  },
  created () {
    var that = this;
    that.term_type = that.$route.query.term_type;
    this.readyInfo();
  },
  methods: {

    estarPhone(phoneNum){
      if(phoneNum){
        let str =  String(phoneNum),
        strLen = str.slice(-7,-3);
        return str.replace(strLen,"****");
      }else{
        return "手机号未设置"
      }
    },
    sendPhoneCode: function () {
      const that = this;
      if (that.mobilePhone === "") {
        mui.toast("error", "手机号码不能为空");
        return;
      }
      let isPhone = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/.test(
        that.mobilePhone
      );
      if (!isPhone) {
        mui.toast("error", "手机格式不正确");
        return;
      }
      // 注册绑定模式: "0", 登录验证:"1", 修改密码模式: "2" 修改手机号3
      const params = {
        smsMode: "7",
        mobile: that.mobilePhone,
      };
      getCaptcha(params).then(that.codeHandle);
    },
    codeHandle(res) {
      const that = this;
      const mui = this.mui;
      const result = res;
      if (result.success) {
        that.countDownCaptcha();
        mui.toast(result.message);
        that.sendCodeBtnDisable = true;
      } else {
        that.sendCodeBtnDisable = false; //错误的情况下 解除禁止
        mui.toast(result.message);
      }
    },
    agreeHandle () {
      this.agreement_show = false;
      this.input_show = true;
      this.ckeckVal = true;
    },
    clickMe () {
      const mui = this.mui;
      if (!this.ckeckVal) {
        mui.toast("请先阅读《用户注销协议须知》");
      }
    },
    go (url) {
      this.$router.push({
        path: url,
      });
    },
    /**
     * 查看协议
     */
    termHandle () {
      this.agreement_show = true;
      this.input_show = false;
    },
    //初次获取信息
    async readyInfo () {
      console.log('111');
      try {
        var params = {};
        let res = await getSickInfo(params);
        if (res.success) {
          console.log(res);
          this.user_id = res.result.id;
          this.mobilePhone=res.result.mobile
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 注销
     */
    async submitHandle (userInfo) {
      try {
        const that = this;
        const mui = this.mui;
        if (that.delCause === "") {
          mui.toast("注销原因不能为空");
          return;
        }
        if (!that.ckeckVal) {
          mui.toast("请同意注销协议");
          return;
        }
        var params = {
          delCause: that.delCause,
          id: that.user_id,
          captcha:that.phoneCaptcha
        };
        let res = await deleteAccount(params).then(that.loginHandle);
        if (res.success) {
          mui.toast(res.message);
          setTimeout(() => {
            that.logout();
            sessionStorage.removeItem("queryParam");
            that.$store.commit("ckeckVal", "");
          }, 1500);
        } else {
          mui.toast(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 原生交互
     * @param tip
     * ios：https://www.jianshu.com/p/426f62f8d1ab
     */
    logout () {
      const mui = this.mui;
      try {
        appClient.toLogin(); //跳转到登录页
      } catch (e) {
        mui.toast("出现错误, 如果在非android环境下访问, 出现该警告是正常的.");
      }
    },
    /*
  * 倒计时
  *
  */
    countDown () {
      const that = this;
      const timer = setInterval(() => {
        that.code_tip = this.code_time + "s";
        // that.code_time--;
        if (that.code_time-- <= 0) {
          clearInterval(timer);
          that.isDisable = false;
          that.code_tip = "已同意相关的协议";
          that.code_time = 10;
        }
      }, 1000);
    },
    countDownCaptcha() {
      const that = this;
      const timer = setInterval(() => {
        that.captcha_tip = this.captcha_time + "s";
        that.captcha_time--;
        if (that.captcha_time <= 0) {
          clearInterval(timer);
          that.sendCodeBtnDisable = false;
          that.captcha_tip = "获取验证码";
          that.captcha_time = 60;
        }
      }, 1000);
    },
    // countDownCaptcha () {
    //   const that = this;
    //   const timer = setInterval(() => {
    //     that.captcha_tip = this.captcha_time + "s";
    //     // that.code_time--;
    //     if (that.captcha_time-- <= 0) {
    //       clearInterval(timer);
    //       that.captcha_tip = "获取验证码";
    //       that.captcha_time = 60;
    //     }
    //   }, 1000);
    // },
  },
};
</script>

 <style lang="scss">
.register {
  margin-top: 50px;
}

.register .page {
  height: 80%;
}

.register .text-margin {
  margin: 0 10px;
  font-size: 12px;
}

.register .agreeRadio-span {
  font-size: 12px;
}
.code-input {
  width: 35% !important;
  float: left !important;
}

.agreeRadio {
  width: 10% !important;
}
.agreeRadio-input {
  position: relative;
  top: 4px;
  right: 10px;
  width: 30px;
  height: 15px;
  text-align: center;
}
.captcha_phone p{
  line-height: 40px;
}
.captcha-item{
  display: flex;
  font-size: 14px;
  align-items: center;
  
}
.get-captcha{
  width: 40%;
  height: 32px;
  display: block;
  margin-right: 4px;
  font-size: 14px;
  color: #fff;
  /* margin-top: 100px; */
  border-radius: 20px;
  border: none !important;

}
::-webkit-input-placeholder { font-size: 14px; }
::-moz-placeholder { font-size: 14px; } /* firefox 19+ */
:-ms-input-placeholder { font-size: 14px; } /* Internet Explorer 10+ */
:-moz-placeholder { font-size: 14px; } 

.updatePwd_btn{
  width: 80% !important;
    height: 40px !important;
    display: block !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    border: none !important;
    color: #fff !important;
}
</style>
