<template >
  <div class="noFound">
    <div class="cell">
      <div class="card mui-text-center">
          <img src="../../assets/img/404.png" class="noFound-image" alt />
          <div class="empty_text">暂无内容</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading",
  components: {},
  data() {
    return {};
  }
};
</script>
<style>
.noFound {
  padding-top: 30%;
  /* background-color: rgba(0, 0, 0, 0.7); */
}
  .noFound-image{
    height: 80px;
  }
  .empty_text{
    color: #cccccc;
  }

</style>
